import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select, ImageUpload, SelectWeighing } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import * as CategoryService from '../../services/categoryBabys';
//import Api from '../../../utils/axios';
import Api from '../../utils/axios';


const CreateDiaryBabys = () => {
  const api = new Api();
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
 
  const [status, setStatus] = useState(false)
  const formik = useFormik({
    initialValues: {
      Name: '',

    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .required('Required'),

    }),
    onSubmit: (data) => {
      const input = {
        Name: data.Name,
        Status: status ? 1 : 0
      };

      SendStage(input);
    }
  });

  const SendStage = async (data) => {
    try {

      setSaveLoading(true);
      await CategoryService.createCategoryBabys(data);
      toast.success(t('categoryBabys.buttons.createSuccess'));
      dispatch(setLoading(false));
      history.push('/categorybabys/');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">{t('categoryBabys.create')}</h3>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('categoryBabys.name')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('categoryBabys.name')}
                        type="text"
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('Name')}
                      />
                    </div>
                  </div>
                  <div className="form-group col-sm-3">
                    <label>{t('categoryBabys.status')}</label>
                    <Checkbox
                      checked={status}
                      onChange={setStatus}
                      label={status ? "Activo" : "inactivo"}
                    />
                  </div>


                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/categorybabys/`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('categoryBabys.buttons.create')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDiaryBabys