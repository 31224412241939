import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea,Checkbox } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import { prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import * as Yup from 'yup';
import * as adviceConsejoService from '../../services/adviceConsejos';


const CreateAdvice = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false)

    const formik = useFormik({
        initialValues: {
            content: '',
            name: ''
        },
        validationSchema: Yup.object({
           
            content: Yup.string()
                .required(t('validationSchema.detailsNewsRequired')),
            name: Yup.string()
                .required(t('validationSchema.infoNewRequired')),
        }),
        onSubmit: (data) => {
            SendAdvice(data);
        }
    });

    const SendAdvice = async (data) => {
        try {
            const adviceInput =
            {
                content: data.content,
                name: data.name,
                Status: status ? 1: 0
            
            }
            setSaveLoading(true);
            await adviceConsejoService.createAdvice(adviceInput);
            toast.success(t('advices.buttons.createSuccess'));
            dispatch(setLoading(false));
            history.push('/adviceconsejos');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('advices.create')}</h3>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                   
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('advices.name')}
                                        </label>
                                        <div className="col-sm-4">
                                            <Input
                                                placeholder={t('advices.name')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('advices.content')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('advices.content')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('content')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label>{ t('advices.status')}</label>
                                        <Checkbox
                                            checked={status}
                                            onChange={setStatus}
                                            label={status? "Activo":"inactivo"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/adviceconsejos`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('advices.buttons.create')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAdvice