import Api from '../utils/axios';
const api = new Api();

export const getAdvice = async () => {
    const advices = await api.getAdvice();
    return advices;
}

export const createAdvice = async (data) => {
    const advices = await api.createAdvice(data);
    return advices;
}

export const deleteAdvice = async (id) => {
    const advices = await api.deleteAdvice(id);
    return advices.data;
}

export const updateAdvice = async (id, data) => {
    const advices = await api.updateAdvice(id, data);
    return advices;
}

export const getAdviceById = async (id) => {
    const advices = await api.getAdviceById(id);
    return advices;
}