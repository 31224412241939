import React, {useState, useEffect, useMemo} from 'react';
import {ContentHeader, Button, Input, Textarea} from '@components';
import {useTranslation} from 'react-i18next';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {Modal} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios';

import {
    customStyles,
    paginationComponentOptions
} from '../../utils/table-config';

const Categories = () => {
    const [t] = useTranslation();
    const api = new Api();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [terms, setTerms] = useState([]);
    const loading = useSelector((state) => state.ui.loading);
    const dispatch = useDispatch();

    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        dispatch(setLoading(true));
        api.getTerms()
            .then((response) => {
                console.log('response', response?.data);
                setTerms(response?.data);
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
            });
    }, []);

    const handleButtonClick = (item) => {
        dispatch(setLoading(true));
        api.deleteTerms(item?.id)
            .then(() => {
                api.getTerms()
                    .then((response) => {
                        setTerms(response?.data);
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const formikCreate = useFormik({
        initialValues: {
            title: '',
            keyname: '',
            description: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required(t('Campo requerido.')),
            keyname: Yup.string().required(t('Campo requerido.')),
            description: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values, {resetForm}) => {
            dispatch(setLoading(true));
            api.postTerms({
                keyName: values?.keyname,
                title: values?.title,
                description: values?.description,
                counter: (terms?.length || 0) + 1
            })
                .then(() => {
                    api.getTerms()
                        .then((response) => {
                            setTerms(response?.data);
                            dispatch(setLoading(false));
                            setOpen(false);
                            resetForm();
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    const formikUpdate = useFormik({
        initialValues: {
            id: '',
            title: '',
            keyname: '',
            description: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required(t('Campo requerido.')),
            keyname: Yup.string().required(t('Campo requerido.')),
            description: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values, {resetForm}) => {
            dispatch(setLoading(true));
            api.putTerms(values?.id, {
                keyName: values?.keyname,
                title: values?.title,
                description: values?.description
            })
                .then(() => {
                    api.getTerms()
                        .then((response) => {
                            setTerms(response?.data);
                            dispatch(setLoading(false));
                            setOpen2(false);
                            resetForm();
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    const columns = useMemo(() => [
        {
            name: '',
            cell: (row) => (
                <>
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => handleButtonClick(row)}
                        id={row.id}
                    >
                        <i className="icon-button fa fa-trash" />
                    </Button>
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => {
                            formikUpdate?.setFieldValue('title', row?.title);
                            formikUpdate?.setFieldValue(
                                'keyname',
                                row?.keyName
                            );
                            formikUpdate?.setFieldValue(
                                'description',
                                row?.description
                            );
                            formikUpdate?.setFieldValue('id', row?.id);
                            setOpen2(true);
                        }}
                        id={row.id}
                    >
                        <i className="icon-button fa fa-edit" />
                    </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
        {
            name: <b>{t('terms.label.titleT')}</b>,
            selector: (row) => row.title,
            sortable: false,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        },
        {
            name: <b>{t('terms.label.keynameT')}</b>,
            selector: (row) => row.keyName,
            sortable: false,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        }
    ]);
    console.log("formik", formikUpdate?.values);
    return (
        <div id="news-list">
            <div>
                <Modal centered show={open} onHide={() => setOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Nuevo término
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formikCreate.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-12">
                                    <label>{t('terms.label.titleT')}</label>
                                    <Input
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'title'
                                        )}
                                        type="text"
                                        value={formikCreate?.values?.title}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('terms.label.keynameT')}
                                    </label>
                                    <Input
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'keyname'
                                        )}
                                        type="text"
                                        value={formikCreate?.values?.keyname}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('terms.label.descriptionT')}
                                    </label>
                                    <Textarea
                                        value={formikCreate.values.description}
                                        formik={formikCreate}
                                        isRichText
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'description'
                                        )}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal centered show={open2} onHide={() => setOpen2(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Editar término
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formikUpdate.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-12">
                                <label>{t('terms.label.titleT')}</label>
                                    <Input
                                        formik={formikUpdate}
                                        formikFieldProps={formikUpdate.getFieldProps(
                                            'title'
                                        )}
                                        type="text"
                                        value={formikUpdate?.values?.title}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('terms.label.keynameT')}
                                    </label>
                                    <Input
                                        formik={formikUpdate}
                                        formikFieldProps={formikUpdate.getFieldProps(
                                            'keyname'
                                        )}
                                        type="text"
                                        value={formikUpdate?.values?.keyname}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('terms.label.descriptionT')}
                                    </label>
                                    <Textarea
                                        value={formikUpdate.values.description}
                                        formik={formikUpdate}
                                        isRichText
                                        formikFieldProps={formikUpdate.getFieldProps(
                                            'description'
                                        )}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen2(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="col-sm-12 d-flex justify-content-between align-items-center">
                <ContentHeader title={t('terms.label.title')} />
                <Button
                    type="button"
                    onClick={() => {
                        setOpen(true);
                    }}
                    className="btn btn-danger ml-2"
                >
                    <i className="icon-button fa fa-plus mr-2" />
                    Nueva registro
                </Button>
            </div>
            {terms && terms.length > 0 ? (
                <DataTable
                    data={terms}
                    customStyles={customStyles}
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    // subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    columns={columns}
                />
            ) : (
                <div>
                    <h1 className="text-center">
                        {t('general.label.noRegisters')}
                    </h1>
                </div>
            )}
        </div>
    );
};

export default Categories;
