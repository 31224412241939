import React, {useState, useEffect, useMemo} from 'react';
import {ContentHeader, Button, FilterComponent} from '@components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import * as WeighingService from '../../../services/babyWeight/weighing';
import {
    customStyles,
    paginationComponentOptions
} from '../../../utils/table-config';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

const headerButtons = [
    {
        text: 'Agregar nuevo',
        urlSlug: '/babyweight/weighing/create'
    }
];

const Weighings = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [weighing, setWeighing] = useState([]);
    const loading = useSelector((state) => state.ui.loading);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days'));
    const [endDate, setEndDate] = useState(moment());

    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: <b>{t('babyWeight.weighing.table.gender')}</b>,
            selector: (row) => (row.gender === 1 ? "Masculino" : 'Femenino'),
            sortable: true
        },
        {
            name: <b>{t('babyWeight.weighing.table.age')}</b>,
            selector: (row) => (row.age === null ? '-' : row.age),
            sortable: true
        },
        {
            name: <b>{t('babyWeight.weighing.table.max_Weight')}</b>,
            selector: (row) => (row.max_Weight),
            sortable: true
        },
        {
            name: <b>{t('babyWeight.weighing.table.min_Weight')}</b>,
            selector: (row) => (row.min_Weight),
            sortable: true
        },
        {
            name: '',
            cell: (row) => (
                <Button
                    className="m-1 btn btn-danger"
                    onClick={() => handleButtonClick(row)}
                    id={row.id}
                >
                    <i className="icon-button fa fa-edit"></i>
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ]);

    // Hooks para el filtro de la tabla
    const [filterCode, setFilterCode] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = weighing.filter(
        (item) => item.gender
    );

    const handleCallback = async (start, end, label) => {
        setStartDate(start);
        setEndDate(end);
    };

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterAuthor) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterAuthor('');
            }

            if (filterCode) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterCode('');
            }
        };

        return (
            <div className="d-flex p-2">
               
                
            </div>
        );
    }, [filterAuthor, filterCode, resetPaginationToggle]);

    const handleButtonClick = (item) => {
        history.push(`/babyweight/weighing/edit/${item.id}`);
    };

    useEffect(() => {
        dispatch(setLoading(true));
        WeighingService
            .getWeighing()
            .then((response) => {
              setWeighing(response.data);
                dispatch(setLoading(false));
            })
            .catch((err) => {
               
                dispatch(setLoading(false));
            });
    }, []);

    return (
        <>
            <div id="news-list">
                <ContentHeader title={t('babyWeight.weighing.label')} buttons={headerButtons} />

                {weighing && weighing.length > 0 ? (
                    <DataTable
                        data={filteredItems}
                        customStyles={customStyles}
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noDataComponent="No se encuentran registros"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                ) : (
                    <div>
                        <h1 className="text-center">
                            {t('general.label.noRegisters')}
                        </h1>
                    </div>
                )}
            </div>
        </>
    );
};

export default Weighings;
