import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, ImageUpload } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import Api from '../../utils/axios'
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as NewsServices from '../../services/stores';

const CreateStore = () => {
    const [t] = useTranslation();
    const api = new Api();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .required('Campo requerido.')
        }),
        onSubmit: (values) => {
            seenDataNews({
                nombre: values?.name
            });
        }
    });

    const seenDataNews = async (data) => {

        try {
            dispatch(setLoading(true));
            setSaveLoading(true);
            await NewsServices.createStore(data);
            toast.success(t('general.label.successRegister'));
            dispatch(setLoading(false));
            history.push('/stores');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde";
            toast.error(errorMessage);
        }
    }

    useEffect(() => {
        dispatch(setLoading(true));
        api
            .getTags()
            .then((response) => {
                setCategories(response.data)
                dispatch(setLoading(false));
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error)
            })
    }, [])

    

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('stores.create.label')}</h3>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6 form-group">
                                        <label
                                            htmlFor="inputName"
                                            className="col-form-label"
                                        >
                                            {t('stores.create.input')}
                                        </label>
                                        <div>
                                            <Input
                                                placeholder={t('stores.create.placeholder')}
                                                type="name"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                            />
                                        </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/stores`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('stores.create.add')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateStore;