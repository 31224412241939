import React, { useState, useEffect, useMemo } from 'react';
import { ContentHeader, Button, FilterComponent } from '@components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as storeService from '../../services/stores';
import { prettyOnlyDateOfBirth, prettyOnlyDate } from '../../utils/pretty-date'
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

const StoreList = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [storesData, setStores] = useState([])
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    
    const columns = useMemo(() => [
        {
            name: <b>{t('stores.list.table.columns.id')}</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{t('stores.list.table.columns.name')}</b>,
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const handleButtonClick = (item) => {
        history.push(`/stores/edit/${item.id}`)
    };

    useEffect(() => {
        dispatch(setLoading(true));
        storeService.getStores()
            .then(response => {
                setStores(response.data)
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })
    }, [])

    return (
        <>
            <div id="news-list">
                <ContentHeader title={t('stores.list.label')}/>

                {storesData && storesData.length > 0 ?
                    <DataTable
                        data={storesData}
                        customStyles={customStyles}
                        progressPending={loading}
                        noDataComponent="No se encuentran registros"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                    : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                    </div>}

            </div>
        </>
    );


};

export default StoreList;