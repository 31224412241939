import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select, ImageUpload, SelectWeighing } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as StageService from '../../../services/babyWeight/stage';
import Api from '../../../utils/axios';
import * as WeighingService from '../../../services/babyWeight/weighing';

const CreateStage = () => {
  const api = new Api();
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState();
  const [weighing, setWeighing] = useState([]);
  const [weighingId, setWeighingId] = useState("");
  const formik = useFormik({
    initialValues: {
      Name: '',
      FileId: '',
      Diaper: 0,
      WeighingId: ""
    },
    validationSchema: Yup.object({
      Name: Yup.string()
      .required('Required'),
      Diaper: Yup.number()
      .required('Required'),
      FileId: Yup.number()
        .required('Required')
    }),
    onSubmit: (data) => {
      const input = {
        Name: data.Name,
        Diaper: data.Diaper,
        FileId: uploadImage != null ? uploadImage.id : null,
        weighingId: weighingId
      };

      SendStage(input);
    }
  });

  const SendStage = async (data) => {
    try {

      setSaveLoading(true);
      await StageService.createStage(data);
      toast.success(t('babyWeight.stage.buttons.createSuccess'));
      dispatch(setLoading(false));
      history.push('/babyweight/stage');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }


  const uploadImageRequest = async (file) => {
    const formData = new FormData();
    const prefix = 'file_';
    const microTime = new Date().getTime() * 1000 + performance.now();
    const extension = file.name.split('.').pop();
    const randomName = `${prefix}${microTime}.${extension}`;
    formData.append('file', new File([file], randomName, { type: file.type }));
    try {
      dispatch(setLoading(true));
      const response = await api.uploadFile(formData);
      setUploadImage(response.data.file);
      formik.setFieldValue("FileId",response.data.file?.id);
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    WeighingService
      .getWeighing()
      .then((response) => {
        setWeighing(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">{t('babyWeight.stage.create')}</h3>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.stage.name')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('babyWeight.stage.name')}
                        type="text"
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('Name')}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-8 row">
                  <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.stage.diaper')}
                    </label>
                    <div className="col-sm-4">
                    <Input
                      formik={formik}
                      formikFieldProps={formik.getFieldProps('Diaper')}
                      type="number"
                      placeholder={t(
                        'babyWeight.stage.diaper'
                      )} />
                           </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="inputName">
                      {t(
                        'babyWeight.stage.weighingId'
                      )}
                    </label>
                    {weighing &&
                      weighing.length > 0 ? (
                      <div className="inputStatus">
                        <SelectWeighing
                          items={
                            weighing
                          }
                          optionTitle="id"
                          onChange={(
                            item
                          ) =>
                            setWeighingId(item.id)
                          }
                          // value={
                          //   formik
                          //     .values
                          //     .state
                          // }
                          formik={formik}
                          formikFieldProps={formik.getFieldProps(
                            'weighingId'
                          )}
                        />
                      </div>
                    ) : null}
                  </div>
                 
                  <div className="col-sm-6">
                    <ImageUpload
                      id="myImage"
                      label={t(
                        'babyWeight.stage.fileId'
                      )}
                      image={
                        uploadImage
                      }
                      witdh={515}
                      height={515}
                      uploadImage={
                        uploadImageRequest
                      }
                      className={formik?.errors.FileId && formik?.touched.FileId ? "is-invalid" : ""}
                    />
                    {formik?.errors.FileId && formik?.touched.FileId ? (
                      <div className="invalid-feedback d-block">{formik?.errors.FileId}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/babyweight/weighing`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('babyWeight.weighing.buttons.create')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateStage