import Api from '../../utils/axios';
const api = new Api();

export const getRecommendation= async () => {
    const advices = await api.getRecommendation();
    return advices;
}

export const createRecommendation = async (data) => {
    const advices = await api.createRecommendation(data);
    return advices;
}

export const deleteRecommendation = async (id) => {
    const advices = await api.deleteRecommendation(id);
    return advices.data;
}

export const updateRecommendation = async (id, data) => {
    const advices = await api.updateRecommendation(id, data);
    return advices;
}

export const getRecommendationById = async (id) => {
    const advices = await api.getRecommendationById(id);
    return advices;
}