import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Select,
    Checkbox,
    SelectState,
    ImageZoom,
    ImageUpload,
    Textarea
} from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik, Formik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as ticketService from '../../services/tickets';
import * as statesService from '../../services/states';
import * as Yup from 'yup';
import Api from '../../utils/axios';
import { prettyOnlyDateOfBirth } from '../../utils/pretty-date';
import priceTotal, {
    quantityTotal,
    pointsTotal,
    pointsTotalOfProduct
} from '../../utils/ticket-total';
import { prettyPrice } from '../../utils/string-manipulation';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import Autocomplete from 'react-autocomplete';

const digitsOnly = /^\d*[\{1}\d*]\d*$/;
const stausValueTicket = [
    { id: 1, name: 'Aceptado', value: 0 },
    { id: 2, name: 'En Proceso', value: 1 },
    { id: 3, name: 'Inválido', value: 2 }
];

const EditTicket = () => {
    const api = new Api();
    const history = useHistory();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    // Variables de logica de productos
    const [productInput, setProductInput] = useState('');
    const [productSelected, setProductSelected] = useState({ id: 0 });
    const [products, setProducts] = useState([]);
    const { id } = useParams();
    const [uploadImage, setUploadImage] = useState();
    // State para el autocomplete de tienda
    const [cadenaInput, setCadenaInput] = useState('');
    const [stores, setStores] = useState([]);
    const [statusTicket, setStatusTicket] = useState();
    const [stateTicket, setStateTicket] = useState();
    const [ticketLoading, setTicketLoading] = useState();
    const [checkParticipation, setCheckParticipation] = useState();

    const [valueStatus, setValueStatus] = useState();
    const [valueStatusCombo, setValueStatusCombo] = useState();
    const [refresh, setRefresh] = useState();
    const [uidClient, setUidClient] = useState();
    // Formik del formulario para agregar productos
    const formikProductSelected = useFormik({
        initialValues: {
            price: 0,
            quantity: 0
        },
        validationSchema: Yup.object({
            price: Yup.number()
                .min(20.0, t('tickets.label.higherPrice'))
                .typeError(t('validationSchema.onlyNumber'))
                .required(t('validationSchema.product.priceRequired')),
            quantity: Yup.string()
                .matches(
                    digitsOnly,
                    t('validationSchema.onlyNumberWithotDecimal')
                )
                .min(1, t('tickets.label.higherQuantity'))
                .required(t('validationSchema.product.quantityRequired'))
        }),
        onSubmit: (values) => {
            handlerAddProductTicket(values);
        }
    });
    // Formik del ticket
    const formik = useFormik({
        initialValues: {
            cadenaId: 0,
            cadena: null,
            address: '',
            cost: 0.0,
            barcode: '',
            dateOfPurchase: '',
            status: 1,
            state: '',
            total: 0,
            valuePoints: 0,
            products: [],
            reason: ""
        },
        validationSchema: Yup.object({
            address: Yup.string()
                .max(250, t('validationSchema.max250Caracters'))
                .when(['status'], (status) => {
                    if (status == 0) {
                        return Yup.string().required(
                            t('validationSchema.required')
                        );
                    }
                }),
            barcode: Yup.string().when(['status'], (status) => {
                if (status == 0) {
                    return Yup.string().required(
                        t('validationSchema.required')
                    );
                }
            }),
            dateOfPurchase: Yup.string().when(['status'], (status) => {
                if (status == 0) {
                    return Yup.string().required(
                        t('validationSchema.required')
                    );
                }
            }),
            status: Yup.number()
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    // busamos el ticket por el id
    useEffect(() => {
        dispatch(setLoading(true));
        ticketService
            .getTicketById(id)
            .then((response) => {
                console.log("=>response",response)
                formik.setValues({ ...response.data });
                setCadenaInput(response?.data?.cadena?.nombre);
                setTicketLoading(response?.data);

                // Buscamos los productos de la cadena para filtro de productos.
                // Agregamos los productos y se agrega el producto en la posición 0.
                api.getProduct(response?.data?.cadenaId)
                    .then((response) => {
                        setProducts(response.data);
                        if (response.data[0] != undefined)
                            setProductSelected(response.data[0]);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                setValueStatus(response.data.status);
                setValueStatusCombo(response.data.status);
                setUidClient(response.data.client.uid);

                dispatch(setLoading(false));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [refresh]);

    // buscamos los datos de state y las tiendas para los dropdows.
    useEffect(() => {
        statesService
            .getState()
            .then((response) => {
                setStateTicket(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        ticketService
            .getStores()
            .then((response) => {
                setStores(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        setStatusTicket(stausValueTicket);
    }, []);

    // Handles TODO: se puede cambiar por una solo funcion y enviar el valor a cambiar.
    const handleServiceRemove = (index) => {
        const newData = formik.values;
        newData.products.splice(index, 1);
        formik.setValues({ ...newData }, true);
    };

    const handleChangeState = (item) => {
        const newData = formik.values;
        newData.state = item.nombre;
        formik.setValues({ ...newData }, true);
    };

    const handleChangeStatus = (item) => {
        const newData = formik.values;
        newData.status = item.value;
        formik.setValues({ ...newData }, true);
    };

    const handlerStoreTicket = async (nameStore) => {
        const store = stores.find((item) => item.nombre === nameStore);
        setCadenaInput(store.nombre);
        dispatch(setLoading(true));
        const response = await api.getProduct(store.id);
        setProducts(response.data);
        if (response.data[0] != undefined) {
        }
        setProductSelected(response.data[0]);

        setProductInput('');
        dispatch(setLoading(false));
        // Cambiamos de cadena el ticket y limpiamos los productos listados en el ticket.
        const newData = formik.values;
        newData.cadena = store;
        newData.cadenaId = store.id;
        newData.products = [];
        formik.setValues({ ...newData }, true);
    };

    // handler para el input de autocomplete
    const handlerProduct = (upc) => {
        setProductInput(upc);
        const product = products.find((item) =>
            (
                item.upc +
                ' - ' +
                item.descripcion +
                ' - ' +
                item.ticket
            ).includes(upc)
        );
        setProductSelected(product);
    };

    // handler para agregar un producto al arreglo de productos.
    const handlerAddProductTicket = (data) => {
console.log("=>uploadImage",uploadImage)
        const newProduct = {
            productId: productSelected.id,
            product: productSelected,
            price: data.price,
            valuePoints: pointsTotalOfProduct(data.price, data.quantity),
            quantity: data.quantity,
            fileId: uploadImage != null ? uploadImage.id : null
        };
        const newData = formik.values;
        newData.products.push(newProduct);
        formik.setValues({ ...newData }, true);
    };

    const usersData = async (data) => {
        const date = new Date();
        const toDay = date;
        const datValidate = new Date(Date.parse(data.dateOfPurchase));
        var timeDiff = datValidate.getTime() - toDay.getTime();
        var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const valueDiffDays = daysDiff * -1;

        if (valueDiffDays > 90) {
            toast.error(t('tickets.label.lastDate'));
            return;
        }

        if (data.status == 0 && data.products.length == 0) {
            toast.error(t('tickets.errors.ticktNotHaveProducts'));
            return;
        }

        const ticket = {
            cadenaId: data.cadenaId,
            address: data.address,
            barcode: data.barcode,
            dateOfPurchase: data.dateOfPurchase,
            status: data.status,
            state: data.state,
            total: priceTotal(data.products),
            valuePoints: pointsTotal(data.products),
            products: data.products,
            hasParticipation: checkParticipation,
            reason: data.reason
        };

        console.log("=>data-ticket",ticket)
        try {
            const respuesta = confirm(t('tickets.label.vilidateTicketPoints'));
            if (respuesta === true) {
                const res = await ticketService.updateTicketByIdProduct(
                    id,
                    ticket
                );
                toast.success(t('tickets.label.vilidateTicket'));
                history.push('/tickets');
            } else {
                return false;
            }
        } catch (error) {
            console.log(error.response.data.errors[0]);
            const errorMessage = error.response.data.errors[0];
            toast.error(errorMessage);
        }
    };

    if (valueStatus === 0) {
        document.querySelector('.inputStatus').style.display = 'none';
        var titleStatus = document.querySelector('.messageAceptStatus');
        titleStatus.innerHTML = 'Aceptado';
        document.querySelector('.btnValidate').disabled = true;
        document.querySelector('.addProductSection').style.display = 'none';
        var title = document.querySelector('.titleSectionProduct');
        title.innerHTML = 'Lista de productos';
        document.querySelector('.dataListProduct').style.display = 'block';
        if (
            document.querySelector('.dataTableAddProduct') &&
            document.querySelector('.componentValidate')
        ) {
            document.querySelector('.dataTableAddProduct').style.display =
                'none';
            document.querySelector('.componentValidate').style.display = 'none';
        }
    }

    if (valueStatus != 0) {
        if (document.querySelector('.dataListProduct')) {
            document.querySelector('.dataListProduct').style.display = 'none';
        }
        if (document.querySelector('.dataTableAddProduct')) {
            document.querySelector('.dataTableAddProduct').style.display =
                'block';
        }
    }

    const validateButton = async () => {
        try {
            const respuesta = confirm(t('tickets.label.validateOnlyTicket'));
            if (respuesta === true) {
                const res = await ticketService.patchTicket(
                    id,
                    formik.values.status,
                    uidClient
                );
                toast.success(t('tickets.label.vilidateTicket'));
                history.push('/tickets');
            } else {
                return false;
            }
        } catch (error) {
            toast.success(t('tickets.label.vilidateTicket'));
            history.push('/tickets');
        }
    };

    const btnStatusValidate = document.querySelector('.btnValidateStatus');
    if (document.querySelector('.btnValidateStatus')) {
        btnStatusValidate.style.display = 'none';
    }

    const handleOnChange = (value) => {
        const switchOption = document.querySelector('.switchOption');
        const btnValidateStatus = document.querySelector('.btnValidateStatus');
        const btnSaveChanges = document.querySelector('.btnSaveChanges');

        if (
            document.querySelector('.btnValidateStatus') &&
            document.querySelector('.btnSaveChanges')
        ) {
            if (switchOption.checked == true) {
                btnValidateStatus.style.display = 'block';
                btnSaveChanges.style.display = 'none';
            } else if (switchOption.checked == false) {
                btnSaveChanges.style.display = 'block';
                btnValidateStatus.style.display = 'none';
            }
        }
    };

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, '0');
        const mm = String(today.getMonth() - 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    };

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append('file', file);
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData);
            setUploadImage(response.data.file);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header mb-1">
                            <h3 className="text-danger font-weight-bold">
                                {t('tickets.label.headerTitle')}
                            </h3>
                        </div>
                        <div className="p-3">
                            {ticketLoading ? (
                                <form
                                    className="form-horizontal"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <div className="form-group row">
                                                <label>
                                                    {t(
                                                        'tickets.label.shopName'
                                                    )}
                                                </label>

                                                <Autocomplete
                                                    getItemValue={(item) =>
                                                        item.nombre
                                                    }
                                                    items={stores}
                                                    renderInput={(props) => {
                                                        return (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                {...props}
                                                            />
                                                        );
                                                    }}
                                                    wrapperStyle={{
                                                        display: 'block',
                                                        position: 'relative',
                                                        display: 'flex',
                                                        width: '100%'
                                                    }}
                                                    shouldItemRender={(
                                                        item,
                                                        value
                                                    ) =>
                                                        item.nombre
                                                            .toLowerCase()
                                                            .indexOf(
                                                                value.toLowerCase()
                                                            ) > -1
                                                    }
                                                    renderItem={(
                                                        item,
                                                        isHighlighted
                                                    ) => (
                                                        <div
                                                            key={item.id}
                                                            style={{
                                                                background:
                                                                    isHighlighted
                                                                        ? 'lightgray'
                                                                        : 'white',
                                                                zIndex: '9',
                                                                padding: '6px'
                                                            }}
                                                        >
                                                            {item.nombre}
                                                        </div>
                                                    )}
                                                    onSelect={(val) =>
                                                        handlerStoreTicket(val)
                                                    }
                                                    onChange={(e) =>
                                                        setCadenaInput(
                                                            e.target.value
                                                        )
                                                    }
                                                    menuStyle={{
                                                        borderRadius: '3px',
                                                        boxShadow:
                                                            '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                        background:
                                                            'rgba(255, 255, 255, 0.9)',
                                                        padding: '2px 0',
                                                        fontSize: '90%',
                                                        position: 'fixed',
                                                        overflow: 'auto',
                                                        maxHeight: '50%',
                                                        height: '250px',
                                                        zIndex: '9',
                                                        witdh: '100%'
                                                    }}
                                                    value={cadenaInput}
                                                />

                                                {/*<ComboBox*/}
                                                {/*    items={stores}*/}
                                                {/*    optionTitle="nombre"*/}
                                                {/*    onChange={(item) => handlerStoreTicket(item)}*/}
                                                {/*    value={formik.values.cadenaId} />*/}
                                            </div>
                                            <div className="form-group row">
                                                <label>
                                                    {t('tickets.label.address')}
                                                </label>
                                                <Input
                                                    value={
                                                        formik.values.address
                                                    }
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'address'
                                                    )}
                                                    type="text"
                                                    placeholder={t(
                                                        'tickets.label.address'
                                                    )}
                                                />
                                            </div>

                                            <div className="form-group row">
                                                <label>
                                                    {t('tickets.label.barCode')}
                                                </label>
                                                <Input
                                                    value={
                                                        formik.values.barcode
                                                    }
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'barcode'
                                                    )}
                                                    type="text"
                                                    placeholder={t(
                                                        'tickets.label.barCode'
                                                    )}
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label>
                                                    {t(
                                                        'tickets.label.dateOfPurchase'
                                                    )}
                                                </label>
                                                <Input
                                                    value={
                                                        prettyOnlyDateOfBirth(
                                                            formik.values
                                                                .dateOfPurchase
                                                        ) || ''
                                                    }
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'dateOfPurchase'
                                                    )}
                                                    type="date"
                                                    min={disablePastDate()}
                                                    placeholder={t(
                                                        'tickets.label.dateOfPurchase'
                                                    )}
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label htmlFor="inputName">
                                                        {t(
                                                            'general.label.status'
                                                        )}
                                                    </label>
                                                    {statusTicket &&
                                                        statusTicket.length > 0 ? (
                                                        <div className="inputStatus">
                                                            <Select
                                                                items={
                                                                    statusTicket
                                                                }
                                                                optionTitle="name"
                                                                onChange={(
                                                                    item
                                                                ) =>
                                                                    handleChangeStatus(
                                                                        item
                                                                    )
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .status
                                                                }
                                                                formik={formik}
                                                                formikFieldProps={formik.getFieldProps(
                                                                    'status'
                                                                )}
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <h5 className="mt-2 ml-2 text-danger messageAceptStatus"></h5>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label htmlFor="inputName">
                                                        {t(
                                                            'general.label.state'
                                                        )}
                                                    </label>
                                                    {stateTicket &&
                                                        stateTicket.length > 0 ? (
                                                        <div className="inputStatus">
                                                            <SelectState
                                                                items={
                                                                    stateTicket
                                                                }
                                                                optionTitle="nombre"
                                                                onChange={(
                                                                    item
                                                                ) =>
                                                                    handleChangeState(
                                                                        item
                                                                    )
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .state
                                                                }
                                                                formik={formik}
                                                                formikFieldProps={formik.getFieldProps(
                                                                    'state'
                                                                )}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label
                                                    htmlFor="inputName"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Razón
                                                </label>
                                                <div className="col-sm-10">
                                                    <Textarea
                                                        placeholder={t('tickets.label.motivoTicket')}
                                                        type="text"
                                                        value={
                                                            formik.values.reason
                                                        }
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('reason')}
                                                    />
                                                </div>
                                            </div>
                                            {/* Start add products to tikcet */}
                                            <form className="form-horizontal">
                                                <div className="card-header -p2">
                                                    <h3 className="text-danger font-weight-bold titleSectionProduct">
                                                        {t(
                                                            'tickets.label.addProduct'
                                                        )}
                                                    </h3>
                                                </div>
                                                <div className="addProductSection">
                                                    <div className="form-row mt-4 mb-4">

                                                        <div className="form-group col-md-5">
                                                            <label className="">
                                                                {t(
                                                                    'tickets.label.nameProduct'
                                                                )}
                                                            </label>
                                                            {products &&
                                                                products.length >
                                                                0 ? (
                                                                <div>
                                                                    <Autocomplete
                                                                        getItemValue={(
                                                                            item
                                                                        ) =>
                                                                            item.ticket
                                                                                ? item.upc +
                                                                                ' - ' +
                                                                                item.descripcion +
                                                                                ' - ' +
                                                                                item.ticket
                                                                                : item.upc +
                                                                                ' - ' +
                                                                                item.descripcion
                                                                        }
                                                                        items={
                                                                            products
                                                                        }
                                                                        renderInput={(
                                                                            props
                                                                        ) => {
                                                                            return (
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    {...props}
                                                                                />
                                                                            );
                                                                        }}
                                                                        wrapperStyle={{
                                                                            display:
                                                                                'block'
                                                                        }}
                                                                        shouldItemRender={(
                                                                            item,
                                                                            value
                                                                        ) =>
                                                                            (
                                                                                item.upc +
                                                                                ' - ' +
                                                                                item.descripcion +
                                                                                ' - ' +
                                                                                item.ticket
                                                                            )
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    value.toLowerCase()
                                                                                ) >
                                                                            -1
                                                                        }
                                                                        renderItem={(
                                                                            item,
                                                                            isHighlighted
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    item.upc
                                                                                }
                                                                                style={{
                                                                                    background:
                                                                                        isHighlighted
                                                                                            ? 'lightgray'
                                                                                            : 'white',
                                                                                    zIndex: '9',
                                                                                    padding:
                                                                                        '6px'
                                                                                }}
                                                                            >
                                                                                {item.ticket
                                                                                    ? item.upc +
                                                                                    ' - ' +
                                                                                    item.descripcion +
                                                                                    ' - ' +
                                                                                    item.ticket
                                                                                    : item.upc +
                                                                                    ' - ' +
                                                                                    item.descripcion}
                                                                            </div>
                                                                        )}
                                                                        onSelect={(
                                                                            val
                                                                        ) =>
                                                                            handlerProduct(
                                                                                val
                                                                            )
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setProductInput(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        menuStyle={{
                                                                            borderRadius:
                                                                                '3px',
                                                                            boxShadow:
                                                                                '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                                            background:
                                                                                'rgba(255, 255, 255, 0.9)',
                                                                            padding:
                                                                                '2px 0',
                                                                            fontSize:
                                                                                '90%',
                                                                            position:
                                                                                'fixed',
                                                                            overflow:
                                                                                'auto',
                                                                            maxHeight:
                                                                                '50%',
                                                                            height: '250px',
                                                                            zIndex: '9'
                                                                        }}
                                                                        value={
                                                                            productInput
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="form-group col-md-2">
                                                            <label>
                                                                {t(
                                                                    'tickets.label.unitPrice'
                                                                )}
                                                            </label>
                                                            <Input
                                                                value={
                                                                    formikProductSelected
                                                                        .values
                                                                        .price
                                                                }
                                                                formik={
                                                                    formikProductSelected
                                                                }
                                                                formikFieldProps={formikProductSelected.getFieldProps(
                                                                    `price`
                                                                )}
                                                                placeholder={t(
                                                                    'tickets.label.unitPrice'
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-2">
                                                            <label>
                                                                {t(
                                                                    'tickets.label.quantity'
                                                                )}
                                                            </label>
                                                            <Input
                                                                value={
                                                                    formikProductSelected
                                                                        .values
                                                                        .quantity
                                                                }
                                                                formik={
                                                                    formikProductSelected
                                                                }
                                                                formikFieldProps={formikProductSelected.getFieldProps(
                                                                    `quantity`
                                                                )}
                                                                placeholder={t(
                                                                    'tickets.label.quantity'
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-3 mt-4">
                                                            <Button
                                                                className="w-10"
                                                                block
                                                                onClick={() =>
                                                                    formikProductSelected.handleSubmit()
                                                                }
                                                                className="btn btn-danger"
                                                            >
                                                                {' '}
                                                                {t(
                                                                    'general.buttons.addProduct'
                                                                )}
                                                            </Button>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <ImageUpload
                                                                id="myImage"
                                                                label={t(
                                                                    'tickets.label.imageProduc'
                                                                )}
                                                                image={
                                                                    uploadImage
                                                                }
                                                                witdh={515}
                                                                height={515}
                                                                uploadImage={
                                                                    uploadImageRequest
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            {/* end add products to tikcet */}

                                            {/* Start list products to tikcet */}
                                            <div className="dataTableAddProduct">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.nameProduct'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.unitPrice'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.quantity'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.valuePoints'
                                                                )}
                                                            </th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formik.values
                                                            .products &&
                                                            formik.values.products.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {  item
                                                                                .product
                                                                                .ticket
                                                                                ? item
                                                                                    .product
                                                                                    .upc +
                                                                                ' - ' +
                                                                                item
                                                                                    .product
                                                                                    .descripcion +
                                                                                ' - ' +
                                                                                item
                                                                                    .product
                                                                                    .ticket
                                                                                : item
                                                                                    .product
                                                                                    .upc +
                                                                                ' - ' +
                                                                                item
                                                                                    .product
                                                                                    .descripcion}
                                                                        </td>
                                                                        <td>
                                                                            {prettyPrice(
                                                                                item.price
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.valuePoints
                                                                            }{' '}
                                                                            Hugs
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={() =>
                                                                                    handleServiceRemove(
                                                                                        index
                                                                                    )
                                                                                }
                                                                                block
                                                                                className="btn btn-danger"
                                                                            >
                                                                                {' '}
                                                                                {t(
                                                                                    'general.buttons.delete'
                                                                                )}{' '}
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="dataListProduct">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.nameProduct'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.quantity'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.valuePoints'
                                                                )}
                                                            </th>
                                                            <th scope="col">
                                                                {t(
                                                                    'tickets.label.IdTicket'
                                                                )}
                                                            </th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formik.values
                                                            .products &&
                                                            formik.values.products.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {
                                                                                item
                                                                                    .product
                                                                                    .descripcion
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            item.value}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.ticketId
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {/* end list products to tikcet */}

                                            <div className="mt-3 row infoProduct">
                                                <div className="col-md-12">
                                                    <strong>
                                                        {t(
                                                            'tickets.label.quantityProduct'
                                                        )}
                                                        :{' '}
                                                        {quantityTotal(
                                                            formik.values
                                                                .products
                                                        )}{' '}
                                                    </strong>
                                                    <br />
                                                    <strong>
                                                        {t(
                                                            'tickets.label.totalPrice'
                                                        )}
                                                        :{' '}
                                                        {prettyPrice(
                                                            priceTotal(
                                                                formik.values
                                                                    .products
                                                            )
                                                        )}
                                                    </strong>
                                                    <br />
                                                    <strong>
                                                        {t(
                                                            'tickets.label.totalPoints'
                                                        )}
                                                        :{' '}
                                                        {pointsTotal(
                                                            formik.values
                                                                .products
                                                        )}
                                                    </strong>
                                                    <br />
                                                    {formik.values
                                                        .promotionRedeemedUser !=
                                                        null ? (
                                                        <>
                                                            <div className="col-sm-12">
                                                                <strong>
                                                                    {t(
                                                                        'tickets.label.codeParticipation'
                                                                    )}{' '}
                                                                    :{' '}
                                                                    {
                                                                        formik
                                                                            .values
                                                                            .promotionRedeemedUser
                                                                            .promotion
                                                                            .code
                                                                    }
                                                                </strong>
                                                                <Checkbox
                                                                    label="Participa para la promoción"
                                                                    checked={
                                                                        checkParticipation
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setCheckParticipation(
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-6"></div>
                                            </div>
                                            <div className="row p-2 pt-4">
                                                <div className="col-2">
                                                    <Link
                                                        to={`/tickets`}
                                                        className="btn text-danger"
                                                    >
                                                        {' '}
                                                        {t(
                                                            'general.buttons.cancel'
                                                        )}{' '}
                                                    </Link>
                                                </div>
                                                <div className="col-sm-4">
                                                    <Button
                                                        type="submit"
                                                        block
                                                        className="btn btn-danger btnValidate btnSaveChanges"
                                                    >
                                                        {' '}
                                                        {t(
                                                            'general.buttons.saveChanges'
                                                        )}{' '}
                                                    </Button>
                                                    <Button
                                                        onClick={validateButton}
                                                        className="btn btn-danger btnValidate btnValidateStatus "
                                                    >
                                                        {' '}
                                                        {t(
                                                            'general.buttons.validateStatus'
                                                        )}{' '}
                                                    </Button>
                                                </div>
                                                <div className="mt-2 ml-4 form-group row form-check componentValidate">
                                                    <input
                                                        name="exampleRadios"
                                                        className="form-check-input switchOption"
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                            handleOnChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <label>
                                                        {t(
                                                            'tickets.label.statusValidate'
                                                        )}
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="ml-4 mb-3 btn btn-outline-danger"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                >
                                                    <i className="icon-button fa fa-question"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="form-group row">
                                                <label
                                                    htmlFor="image"
                                                    className="col-sm-12 col-form-label"
                                                >
                                                    {t(
                                                        'tickets.label.imageTicket'
                                                    )}
                                                </label>
                                            </div>
                                            <ImageZoom
                                                src={formik.values.image.url}
                                            />
                                        </div>
                                    </div>

                                    {/*Agregar productos*/}
                                </form>
                            ) : null}
                            <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5
                                                className="modal-title text-danger font-weight-bold"
                                                id="exampleModalLabel"
                                            >
                                                {t(
                                                    'tickets.label.statusValidate'
                                                )}
                                            </h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {t('tickets.label.showButton')}{' '}
                                            <strong>
                                                {' '}
                                                {t(
                                                    'tickets.label.validateStatus'
                                                )}{' '}
                                            </strong>{' '}
                                            {t('tickets.label.changeStatus')}
                                            <br />
                                            <br />
                                            {t('tickets.label.modalText4')}{' '}
                                            <strong>
                                                {t('tickets.label.modalText5')}
                                            </strong>{' '}
                                            {t('tickets.label.modalText6')}{' '}
                                            <strong>
                                                {t('tickets.label.modalText7')}
                                            </strong>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-dismiss="modal"
                                            >
                                                {t(
                                                    'general.buttons.itIsUnderstood'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditTicket;
