import React, { useState, useEffect } from 'react';
import { Textarea, Button, Input, ComboBox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as RewardRedeemService from '../../services/rewords';
import prettyDateOnly from '../../utils/pretty-date'
import prettyOnlyDate from '../../utils/pretty-date'
import * as Yup from 'yup';
import Api from '../../utils/axios'
import './RewardRedeem.css';
import { Modal } from 'react-bootstrap';



const RewardRedeem = () => {
    const api = new Api();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingOrder, setLoadingOrder] = useState(false)
    const [rewardUser, setRewardUser] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [points, setPoints] = useState(false);
    const [load, setLoad] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [address, setAddress] = useState(false);
    const [status, setStatus] = useState();
    const [pickUpDate, setPickupDate] = useState();
    const [t] = useTranslation();
    const { idRedeem  } = useParams()
    const { idUser } = useParams();
    const [packages, setPackages] = useState([]);
    const [quote, setQuote] = useState();
    const [loadListPackages, setLoadListPackages] = useState(false);
    const rewardRedeemData = async (data) => {
        try {
            const response = await RewardRedeemService.patchRewords(idUser, idRedeem, data);
            toast.success(t('rewards.label.claimedBounty'));
            history.push('/rewards/redeem');
        } catch (error) {
            setRewardUser(false);
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    useEffect(() => {
        getPackages();
        if (loading) {
            RewardRedeemService.getByIdClientIdRedeem(idUser, idRedeem)
                .then((response) => {
                    setRewardUser([response.data]);
                    setDataUser(response.data?.data);
                    setStatus(response.data.status);
                    setPickupDate(response.data.pickupDate);
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    console.log(error)
                })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            estimatedDeliveryDate: pickUpDate ? pickUpDate?.substring(0,10) : '',
            status: status || 0,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            rewardRedeemData({
                status: values?.status
            });
        }
    });

    const getPackages = async () => {
        try {
            setLoadListPackages(true);
            const response = await RewardRedeemService.getListPackagesAvalaible(idRedeem);
            setQuote(response?.data?.data);
            setPackages(response.data?.data?.rates?.filter((v)=>!v?.error));
            setLoadListPackages(false);
            return response;
        } catch (error) {
            setLoadListPackages(false);
            const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde.";
            toast.error(errorMessage);
        }
    }

    const getRedeemById = () => {
        RewardRedeemService.getByIdClientIdRedeem(idUser, idRedeem)
        .then((response) => {
            setRewardUser([response.data]);
            setDataUser(response.data?.data);
            setStatus(response.data.status);
            setPickupDate(response.data.pickupDate);
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error)
        })
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

        if (status === 1) {
            // document.querySelector(".inputDate").style.display  = "none";
            // document.querySelector(".btn-assign-date").style.display  = "none";
            // document.querySelector(".header-date").style.display  = "none";
    }
     
    const formikCreate = useFormik({
        initialValues: {
            dateYear: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            dateYear: Yup.string().required('Campo requerido.')
        }),
        onSubmit: (values) => {
            setLoad(true);
        }
    });

    const formikOrder = useFormik({
        initialValues: {
            package: '',
            who_delivers: '',
            contact_email: '',
            contact_phone: '',
            origin_address: '',
            pickup_date: '',
            pickup_place: '',
            pickup_place_data: '',
            description_reference: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            package: Yup.string().required('Campo requerido.'),
            who_delivers: Yup.string().required('Campo requerido.'),
            contact_email: Yup.string().email('Correo electrónico inválido.').required('Campo requerido.'),
            contact_phone: Yup.string().matches(/^[0-9]{10}$/, 'Número de teléfono inválido').required('Campo requerido.'),
            origin_address: Yup.string().required('Campo requerido.'),
            pickup_date: Yup.string().required('Campo requerido.'),
            pickup_place: Yup.string().required('Campo requerido.'),
            pickup_place_data: Yup.string().required('Campo requerido.'),
            description_reference: Yup.string().required('Campo requerido.'),
        }),
        onSubmit: (values, { resetForm }) => {
            const {who_delivers, contact_email, contact_phone, origin_address, pickup_date, pickup_place, pickup_place_data, description_reference} = values;
            const dateTimeString = pickup_date;
            const dateTime = new Date(dateTimeString);

            const formattedDate = `${dateTime.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
            const formattedTime = `${dateTime.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true })}`;

            const formattedDateTime = `${formattedDate} ${formattedTime}`;
            const formatCorrectlyDate = formattedDateTime.replace(/(a|p)\.\s*m\./g, "$1m");
            setLoadingOrder(true);
            RewardRedeemService.createOrder(idRedeem, {
                order: {
                    type: "create_order",
                    data: {
                     detail: packages?.find((v)=>v?.provider_id === values?.package),
                     uuid: quote?.transaction?.uuid
                    }
                   }
            })
                .then((response) => {
                    console.log("response order:", response);
                    toast.success(response?.data?.data?.status_name);
                    toast.info("Generando recolección.");
                    RewardRedeemService.createGather(idRedeem, {
                        client_id: idUser,
                        who_delivers,
                        contact_email,
                        contact_phone,
                        data: [{
                            trx_id: response?.data?.data?.trx_uuid,
                            origin_address
                        }],
                        pickup_date: formatCorrectlyDate,
                        pickup_place,
                        pickup_place_data,
                        origin: origin_address,
                        provider_id: formikOrder?.values?.package,
                        description_reference
                    })
                    .then(() => {
                        getRedeemById();
                        setLoadingOrder(false);
                        setOpen(false);
                        resetForm();
                        toast.success("Recolección creada exitosamente.");
                    }).catch((error) => {
                        getRedeemById();
                        setLoadingOrder(false);
                        const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde, fallo al hacer la recolección.";
                        toast.error(errorMessage);
                    })

                }).catch(error => {
                    setLoadingOrder(false)
                    console.log(error);
                    const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde.";
                    toast.error(errorMessage);
                })
        }
    });

    const formattedAmount = (amount) => {
        return parseFloat(amount).toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
      });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('rewards.label.infoClient')}</h3>
                    </div>
                        <div className="p-3">
                        {rewardUser && (rewardUser.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="form-row">
                                        
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.clientId')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.clientId}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('general.label.name')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.name}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('general.label.lastName')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.lastName}
                                            />
                                        </div>


                                    </div>

                                    <div className="form-row mt-3">

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.street')}</label>
                                            <input
                                                disabled
                                                value={item.addressDelivery.street}
                                                className="form-control"
                                                placeholder="Calle" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.externalNumber')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.externalNumber}
                                                placeholder="Número Externo" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.InternalNumber')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.internalNumber === null ? '-' : item.addressDelivery.internalNumber}
                                                placeholder={t('address.label.InternalNumberOptional')} />
                                        </div>


                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.postalCode')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.postalCode}
                                                placeholder="Código Postal"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.city')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.city}
                                                placeholder="Ciudad" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.country')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.country}
                                                placeholder="País" />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.state')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.state}
                                                placeholder="Estado" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label> {t('address.label.hometown')} </label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.hometown}
                                                placeholder="Municipio" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label> {t('address.label.phone')} </label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.phone}
                                                placeholder="Telefono" />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.pointsClient')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.pointValue}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.redeemedPoints')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.pointRedeemed}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.rewardName')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.reward.name}
                                            />
                                        </div>



                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.status')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={
                                                    item.status > 0 ? 'Enviada' : 'Pendiente'
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.updateDate')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={prettyOnlyDate(item.updatedAt)}
                                            />
                                        </div>


                                        <div className="form-group col-md-12 flex-wrap">
                                            <label className="mr-3" >{t('rewards.label.imageReward')}</label>
                                            <div className="d-flex flex-row gap-3" style={{ gap: '20px'}}>
                                                {item?.reward?.images?.map((v, k)=>(
                                                    <div key={k}>
                                                        <img src={v.url} alt="image" height={100} width={100} />                                                    
                                                    </div>
                                                ))}
                                            </div>
                                        </div>



                                    </div>

                                </div>
                            )
                            }))}
                        </div>
                </div>

                <div className="card">

                    <div className="card-header header-date -p2">
                        <h3 className="text-danger font-weight-bold">{t('rewards.label.deliveryDate')} y estatus</h3>
                        
                    </div>
                    
                    <div className="form-row mt-3" >
                        <div className="form-group col-md-9" >
                            <div className="p-3">
                            {status === 0 ? (
                                    <Button
                                        className="mr-1 btn btn-danger"
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        Cotizar
                                    </Button>
                                ) : null}
                            {status !== 0 ? (
                                <form className="form-horizontal form-date-reward" onSubmit={formik.handleSubmit}>
                                    <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <p>Fecha estimada de entrega</p>
                                                <Input
                                                    formik={formik}
                                                    className="inputDate mb-3"
                                                    disable={true}
                                                    name="first"
                                                    formikFieldProps={formik.getFieldProps(
                                                        'first'
                                                    )}
                                                    value={dataUser?.estimatedDeliveryDate ? new Date(dataUser?.estimatedDeliveryDate)?.toISOString()?.substring(0,10): "-"}
                                                />                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <p>Fecha de recolección</p>
                                                <Input
                                                    formik={formik}
                                                    className="inputDate mb-3"
                                                    disable={true}
                                                    name="second"
                                                    formikFieldProps={formik.getFieldProps(
                                                        'second'
                                                    )}
                                                    value={dataUser?.pickupDate ? new Date(dataUser?.pickupDate)?.toISOString()?.substring(0,10) : "-"}
                                                /> 
                                            </div>
                                            <div className="form-group col-md-6">
                                                <p>Traking</p>
                                                <Input
                                                    formik={formik}
                                                    className="inputDate mb-3"
                                                    disable={true}
                                                    name="third"
                                                    formikFieldProps={formik.getFieldProps(
                                                        'third'
                                                    )}
                                                    value={dataUser?.trackingId ? ",," : "-"}
                                                /> 
                                            </div>
                                            <div className="form-group col-md-6">
                                                <p>Estatus</p>
                                                <ComboBox
                                                    items={[
                                                        {id: 0, value: "Pendiente"},
                                                        {id: 1, value: "Enviada"},
                                                        {id: 2, value: "Cancelado"}
                                                    ]}
                                                    optionTitle="value"
                                                    onChange={ (e) => {
                                                        formik?.setFieldValue("status", e?.id);
                                                    }}
                                                    value={formik.values.status}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'status'
                                                    )}
                                                    placeholder="Status"
                                                />
                                            </div>

                                    </div>
                                    <div className="row p-2 pt-4">
                                        <div className="col-2">
                                            <Link
                                                to={`/rewards/redeem`}
                                                className="btn text-danger btn-cancel" > {t('general.buttons.cancel')} </Link>
                                        </div>
                                        <div className="col-sm-2">
                                            <Button
                                                type="submit" block
                                                className="btn btn-danger btn-assign-date" > Actualizar </Button>
                                        </div>
                                    </div>
                                </form>
                             ) : null}

                            </div>
                        </div>
                        <div className="form-group col-md-3 font-italic" >
                            <p> <p className="font-weight-bold" ></p> {t('rewards.label.text1')}<p className="font-weight-bold"> {t('rewards.label.text2Important')} </p></p>
                        </div>
                    </div>

                </div>
            </div>
            <Modal centered show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger font-weight-bold">
                        Crear orden y recoleccion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ margin: "0 0 10px 0", fontSize: "24px" }}>Paqueteria</p>
                    <ComboBox
                        items={packages?.map((x)=>({
                            id: x?.provider_id,
                            value: `${x?.provider_name || ""} ${x?.service_name || ""}  - ${formattedAmount(x?.charge?.total || 0)}`
                        }))}
                        onChange={ (e) => {
                            formikOrder?.setFieldValue("package", e?.id);
                        }}
                        optionTitle={"value"}
                        value={formikOrder.values.status}
                        formik={formikOrder}
                        formikFieldProps={formik.getFieldProps(
                            'package'
                        )}
                    />
                    <p style={{ margin: "20px 0 10px 0", fontSize: "24px" }}>Informacion de recoleccion</p>
                    { loadListPackages ? (<p style={{ color: "red" }}>Cargando opciones de paqueterias....</p>) : null}
                    
                    {packages?.length > 0 ? (
                        <div style={{
                            height: "400px",
                            overflow: "scroll"
                        }}>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>who_delivers</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'who_delivers'
                                )}
                                type="text"
                                value={formikOrder?.values?.who_delivers}
                            /> 
                        </div>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>Email</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'contact_email'
                                )}
                                type="email"
                                value={formikOrder?.values?.contact_email}
                            /> 
                        </div>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>Teléfono</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'contact_phone'
                                )}
                                type="phone"
                                value={formikOrder?.values?.contact_phone}
                            /> 
                        </div>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>pickup_date</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'pickup_date'
                                )}
                                type="datetime-local"
                                value={formikOrder?.values?.pickup_date}
                            /> 
                        </div>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>pickup_place</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'pickup_place'
                                )}
                                type="text"
                                value={formikOrder?.values?.pickup_place}
                            /> 
                        </div>
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>pickup_place_data</p>
                            <Input
                                formik={formikOrder}
                                formikFieldProps={formikOrder.getFieldProps(
                                    'pickup_place_data'
                                )}
                                type="text"
                                value={formikOrder?.values?.pickup_place_data}
                            /> 
                        </div> 
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>origin_address</p>
                            <Textarea
                                type="text"
                                formik={formikOrder}
                                formikFieldProps={formik.getFieldProps('origin_address')}
                                value={formikOrder?.values?.origin_address}
                            />  
                        </div>                   
                        <div>
                            <p style={{ margin: "20px 0 10px 0", }}>description_reference</p>
                            <Textarea
                                type="text"
                                formik={formikOrder}
                                formikFieldProps={formik.getFieldProps('description_reference')}
                                value={formikOrder?.values?.description_reference}
                            /> 
                        </div>                         
                        </div>
                    ) : null}
                                      
                </Modal.Body>
                {packages?.length > 0 ? (
                    <Modal.Footer>
                        <Button
                            isLoading={loadingOrder}
                            className="btn btn-danger"
                            onClick={() => formikOrder?.handleSubmit()}
                        >
                            Enviar
                        </Button>
                    </Modal.Footer>                    
                ) : null}

            </Modal>
        </>
    );
};

export default RewardRedeem;