import Api from '../../utils/axios';
const api = new Api();

export const getStage= async () => {
    const advices = await api.getStage();
    return advices;
}

export const createStage = async (data) => {
    const advices = await api.createStage(data);
    return advices;
}

export const deleteStage = async (id) => {
    const advices = await api.deleteStage(id);
    return advices.data;
}

export const updateStage = async (id, data) => {
    const advices = await api.updateStage(id, data);
    return advices;
}

export const getStageById = async (id) => {
    const advices = await api.getStageById(id);
    return advices;
}