import Api from '../utils/axios';
const api = new Api();

export const getCategoryBabys= async () => {
    const categoryBabys = await api.getCategoryBaby();
    return categoryBabys;
}

export const createCategoryBabys = async (data) => {
    const categoryBabys = await api.createCategoryBaby(data);
    return categoryBabys;
}

export const deleteCategoryBabys = async (id) => {
    const categoryBabys = await api.deleteCategoryBaby(id);
    return categoryBabys.data;
}

export const updateCategoryBabys = async (id, data) => {
    const categoryBabys = await api.updateCategoryBaby(id, data);
    return categoryBabys;
}

export const getCategoryBabysById = async (id) => {
    const categoryBabys = await api.getCategoryBabyById(id);
    return categoryBabys;
}