/* eslint-disable no-console */
import React, {useState, useEffect, useMemo} from 'react';
import {ContentHeader, Button, Input} from '@components';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import * as RedeemService from '../../services/rewords';
import {prettyOnlyDate} from '../../utils/pretty-date';
import {paginationComponentOptions} from '../../utils/table-config';

const Redeem = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [redeem, setRedeem] = useState([]);
    const [_, setRedeemDigitals] = useState([]);
    const loading = useSelector((state) => state.ui.loading);
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const STATUS = {
        0: 'Pendiente',
        1: 'Enviada',
        2: 'Cancelado'
    };
    const customStyles = {
        rows: {
            style: {
                fontSize: '14px'
            },
        },
        headCells: {
            style: {
                fontSize: '16px',
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '20px'
            }
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '20px',
                width: '100px'
            }
        }
    };

    const columns = useMemo(() => [
        {
            name: <b>{t('general.label.name')}</b>,
            selector: (row) => row.client.name,
            sortable: true
        },
        {
            name: <b>{t('general.label.email')}</b>,
            selector: (row) => row.client.email,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        },
        {
            name: <b>{t('general.label.status')}</b>,
            selector: (row) => STATUS[row.status],
            sortable: true
        },
        {
            name: <b>{t('general.label.points')}</b>,
            selector: (row) => row.pointRedeemed,
            sortable: true
        },
        {
            name: <b>{t('rewards.label.reward')}</b>,
            selector: (row) => row.reward.name,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        },
        {
            name: <b>{t('rewards.label.deliveryDate')}</b>,
            selector: (row) =>
                row.estimatedDeliveryDate === null
                    ? '-'
                    : prettyOnlyDate(row.estimatedDeliveryDate),
            sortable: true,
            width: 100
        },
        // {
        //    name: <b>{t('general.label.dateOfCreated')}</b>,
        //    selector: row => prettyDate(row.createdAt),
        //    sortable: true,
        // },
        // {
        //    name: <b>{t('rewards.label.updateDate')}</b>,
        //    selector: row => prettyDate(row.updatedAt),
        //    sortable: true,
        // },
        {
            name: '',
            cell: (row) => (
                <Button
                    className="mr-1 btn btn-danger"
                    onClick={() => handleButtonClick(row)}
                    id={(row.clientId, row.id)}
                >
                    <i className="icon-button fa fa-edit" />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ]);

    useEffect(() => {
        dispatch(setLoading(true));
        RedeemService.getRewordsRedeem()
            .then((response) => {
                setRedeem(response);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            });

        RedeemService.getRewardsRedeemDigitals()
            .then((response) => {
                const digitalsHistory = response.map((item) => {
                    return {
                        Date_Time: item.transactionDateTime,
                        Terminal_Id: item.terminalId,
                        ResponseTransaction_Id: item.transactionId,
                        Invoice_Id: item.Id,
                        Product_Id: item.productId,
                        Amount_Id: item.amount,
                        Account_Id: item.phoneNumber,
                        ResponseCode: item.responseCode
                    };
                });
                console.log(digitalsHistory);
                setRedeemDigitals(digitalsHistory);
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleButtonClick = (item) => {
        history.push(`/rewards/rewardredeem/${item.clientId}/${item.id}`);
    };

    const downloadFile = (file) => {
        const linkSource = `data:application/vnd.ms-excel;base64,${file}`;
        const downloadLink = document.createElement('a');
        const fileName = `report.xls`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const formikCreate = useFormik({
        initialValues: {
            dateYear: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            dateYear: Yup.string().required('Campo requerido.')
        }),
        onSubmit: (values) => {
            setLoad(true);
            RedeemService.UpdateStatusPackage({
                Year: values?.dateYear?.substring(0, 4),
                Month: values?.dateYear?.substring(5, 7)
            })
                .then(() => {
                    setLoad(false);
                    setOpen(false);
                    toast.success('Ejecucion correcta de cambio de estatus.');
                })
                .catch((error) => {
                    const errorMessage = error.response.data.errors[0];
                    toast.error(errorMessage);
                    setLoad(false);
                });
        }
    });
    return (
        <div className="container-fluid" id="news-list">
            <div>
                <ContentHeader title={t('menusidebar.label.redeem')} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        margin: '0 0 20px 0'
                    }}
                >
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Cambio de estatus
                    </Button>
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => {
                            RedeemService.getReport()
                                .then((response) => {
                                    downloadFile(response?.data);
                                    dispatch(setLoading(false));
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }}
                    >
                        Exportar a CSV
                    </Button>
                </div>
            </div>
            {redeem && redeem.length > 0 ? (
                <DataTable
                    data={redeem}
                    customStyles={customStyles}
                    progressPending={loading}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    columns={columns}
                />
            ) : (
                <div>
                    <h1 className="text-center">
                        {t('general.label.noRegisters')}
                    </h1>
                </div>
            )}
            <Modal centered show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger font-weight-bold">
                        Cambio de estatus
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Año"
                        formik={formikCreate}
                        formikFieldProps={formikCreate.getFieldProps(
                            'dateYear'
                        )}
                        type="month"
                        value={formikCreate?.values?.dateYear}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        isLoading={load}
                        className="btn btn-danger"
                        onClick={() => formikCreate?.handleSubmit()}
                    >
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Redeem;
