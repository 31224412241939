import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select, ImageUpload, SelectWeighing } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as StageService from '../../../services/babyWeight/stage';
import Api from '../../../utils/axios';
import * as WeighingService from '../../../services/babyWeight/weighing';

const EditStage = () => {
  const api = new Api();
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState();
  const [weighing, setWeighing] = useState([]);
  const [weighingId, setWeighingId] = useState("");
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      Name: '',
      FileId: '',
      Diaper: 0,
      WeighingId: "",
      Imagen: null,
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .required('Required'),
      Diaper: Yup.number()
        .required('Required'),
      FileId: Yup.number()
        .required('Required'),
    }),
    onSubmit: (data) => {

      SendStage(data);
    }
  });

  const SendStage = async (data) => {
    try {
      const input = {
        Name: data.Name,
        Diaper: data.Diaper,
        FileId: uploadImage != null ? uploadImage.id : formik.values.FileId,
        weighingId: weighingId
      };

      setSaveLoading(true);
      await StageService.updateStage(id, input);
      history.push('/babyweight/stage');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }

  const handleDelete = async () => {
    try {
      const respuesta = confirm(t('general.label.deleteRegister'));
      if (respuesta === true) {
        const data = await StageService.deleteStage(id);
        toast.success(t('babyWeight.stage.buttons.deleteSuccess'));
        history.push('/babyweight/stage');
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImageRequest = async (file) => {
    const formData = new FormData();
    const prefix = 'file_';
    const microTime = new Date().getTime() * 1000 + performance.now();
    const extension = file.name.split('.').pop();
    const randomName = `${prefix}${microTime}.${extension}`;
    formData.append('file', new File([file], randomName, { type: file.type }));
    try {
      dispatch(setLoading(true));
      const response = await api.uploadFile(formData)
      setUploadImage(response.data.file);
      formik.setFieldValue("FileId",response.data.file?.id);
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    WeighingService
      .getWeighing()
      .then((response) => {
        setWeighing(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(setLoading(true));
    StageService
      .getStageById(id)
      .then((response) => {
        const weighingId = response.data.weighingId;
        const uploadImage = response.data.file;
        setUploadImage(uploadImage)
        setWeighingId(weighingId)
        formik.setValues(
          {
            Name: response.data.name,
            Diaper: response.data.diaper,
            FileId: response.data.fileId
          },
          true
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">
              {t('babyWeight.stage.edit')}
            </h3>
            <div className="mr-1 card-tools">
              <Button
                type="submit"
                block
                onClick={handleDelete}
                className="btn btn-danger"
              >
                <i className="icon-button fa fa-trash"></i>
              </Button>
            </div>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.stage.name')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('babyWeight.stage.name')}
                        type="text"
                        formik={formik}
                        value={formik.values.Name}
                        formikFieldProps={formik.getFieldProps('Name')}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-8 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.stage.diaper')}
                    </label>
                    <div className="col-sm-4">
                      <Input
                        formik={formik}
                        value={formik.values.Diaper}
                        formikFieldProps={formik.getFieldProps('Diaper')}
                        type="number"
                        placeholder={t(
                          'babyWeight.stage.diaper'
                        )} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="inputName">
                      {t(
                        'babyWeight.stage.weighingId'
                      )}
                    </label>
                    {weighing &&
                      weighing.length > 0 ? (
                      <div className="inputStatus">
                        <SelectWeighing
                          items={
                            weighing
                          }
                          optionTitle="id"
                          onChange={(
                            item
                          ) =>
                            setWeighingId(item.id)
                          }
                          value={weighingId}
                          formik={formik}
                          formikFieldProps={formik.getFieldProps(
                            'weighingId'
                          )}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-sm-6">
                    <ImageUpload
                      id="myImage"
                      label={t(
                        'babyWeight.stage.fileId'
                      )}
                      image={
                        uploadImage
                      }
                      witdh={515}
                      height={515}
                      uploadImage={
                        uploadImageRequest
                      }
                      className={formik?.errors.FileId && formik?.touched.FileId ? "is-invalid" : ""}
                    />
                    {formik?.errors.FileId && formik?.touched.FileId ? (
                      <div className="invalid-feedback d-block">{formik?.errors.FileId}</div>
                    ) : null}
                  </div>

                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/babyweight/weighing`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('babyWeight.weighing.buttons.edit')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditStage