import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select, ImageUpload, SelectWeighing } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Api from '../../utils/axios';
import * as CategoryBabysService from '../../services/categoryBabys';

const EditDiaryBabys = () => {
  const api = new Api();
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const [status, setStatus] = useState(false)
  const formik = useFormik({
    initialValues: {
      Name: '',
     
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .required('Required'),
    
    }),
    onSubmit: (data) => {

      SendStage(data);
    }
  });

  const SendStage = async (data) => {
    try {
      const input = {
        Name: data.Name,
        Status: status ? 1 : 0
      };


      setSaveLoading(true);
      await CategoryBabysService.updateCategoryBabys(id, input);
      history.push('/categorybabys/');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }

  const handleDelete = async () => {
    try {
      const respuesta = confirm(t('general.label.deleteRegister'));
      if (respuesta === true) {
        const data = await CategoryBabysService.deleteCategoryBabys(id);
        toast.success(t('categoryBabys.buttons.deleteSuccess'));
        history.push('/categorybabys/');
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {

    dispatch(setLoading(true));
    CategoryBabysService
      .getCategoryBabysById(id)
      .then((response) => {
        const estatus = response.data.status === 0 ? false : true;
        setStatus(estatus);
        formik.setValues(
          {
            Name: response.data.name,
            Diaper: response.data.diaper,
            FileId: response.data.fileId
          },
          true
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">
              {t('categoryBabys.edit')}
            </h3>
            <div className="mr-1 card-tools">
              <Button
                type="submit"
                block
                onClick={handleDelete}
                className="btn btn-danger"
              >
                <i className="icon-button fa fa-trash"></i>
              </Button>
            </div>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('categoryBabys.name')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('categoryBabys.name')}
                        type="text"
                        formik={formik}
                        value={formik.values.Name}
                        formikFieldProps={formik.getFieldProps('Name')}
                      />
                    </div>
                  </div>
                  <div className="form-group col-sm-3">
                    <label>{t('categoryBabys.status')}</label>
                    <Checkbox
                      checked={status}
                      onChange={setStatus}
                      label={status ? "Activo" : "inactivo"}
                    />
                  </div>
                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/categorybabys/`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('categoryBabys.buttons.edit')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditDiaryBabys