import React, { useState, useEffect, useMemo } from 'react';
import { ContentHeader, Button, FilterComponent } from '@components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as stageService from '../../../services/babyWeight/stage';
import {
  customStyles,
  paginationComponentOptions
} from '../../../utils/table-config';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';

const headerButtons = [
  {
    text: 'Agregar nuevo',
    urlSlug: '/babyweight/stage/create'
  }
];

const Stage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [stage, setStage] = useState([]);
  const loading = useSelector((state) => state.ui.loading);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'));
  const [endDate, setEndDate] = useState(moment());

  const columns = useMemo(() => [
    {
      name: <b>Id</b>,
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: <b>{t('babyWeight.stage.table.name')}</b>,
      selector: (row) => (row.name === null ? "-" : row.name),
      sortable: true
    },
    {
      name: <b>{t('babyWeight.stage.table.diaper')}</b>,
      selector: (row) => (row.diaper === null ? "-" : row.diaper),
      sortable: true
    },
    {
      name: <b>{t('babyWeight.stage.table.createdAt')}</b>,
      selector: (row) => (row.createdAt.slice(0, 10)),
      sortable: true
    },

    {
      name: '',
      cell: (row) => (
        <Button
          className="m-1 btn btn-danger"
          onClick={() => handleButtonClick(row)}
          id={row.id}
        >
          <i className="icon-button fa fa-edit"></i>
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]);
  // Hooks para el filtro de la tabla
  const [filterCode, setFilterCode] = useState('');
  const [filterAuthor, setFilterAuthor] = useState('');
  // Paginations Reset
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Funcion principal para el filtro multiple
  const filteredItems = stage.filter(
    (item) => item.name
  );

  const handleCallback = async (start, end, label) => {
    setStartDate(start);
    setEndDate(end);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterAuthor) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterAuthor('');
      }

      if (filterCode) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterCode('');
      }
    };

    return (
      <div className="d-flex p-2">


      </div>
    );
  }, [filterAuthor, filterCode, resetPaginationToggle]);

  const handleButtonClick = (item) => {
    history.push(`/babyweight/stage/edit/${item.id}`);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    stageService
      .getStage()
      .then((response) => {
        setStage(response.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {

        dispatch(setLoading(false));
      });
  }, []);
  return (
    <>
      <div id="news-list">
        <ContentHeader title={t('babyWeight.stage.label')} buttons={headerButtons} />

        {stage && stage.length > 0 ? (
          <DataTable
            data={filteredItems}
            customStyles={customStyles}
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            noDataComponent="No se encuentran registros"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            highlightOnHover={true}
            columns={columns}
          />
        ) : (
          <div>
            <h1 className="text-center">
              {t('general.label.noRegisters')}
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default Stage;
