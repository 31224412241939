import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select, ImageUpload, SelectWeighing, SelectState2 } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as StageService from '../../../services/babyWeight/stage';
import Api from '../../../utils/axios';
import * as RecommendationService from '../../../services/babyWeight/recommendation';
const EditRecommendation = () => {
  const api = new Api();
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState();
  const [stage, setStage] = useState();
  const [stageId, setStageId] = useState("");
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      content: '',
      url: '',
      fileId: 0,
      stageId: "",
      file: null
    },
    validationSchema: Yup.object({
      content: Yup.string()
        .required('Required'),
      url: Yup.string()
        .required('Required'),
      // stageId:Yup.string()
      // .required('Required'),
    }),
    onSubmit: (data) => {
      const input = {
        content: data.content,
        url: data.url,
        fileId: uploadImage != null ? uploadImage.id : null,
        stageId: data?.stageId
      };

      SendStage(input);
    }
  });

  const SendStage = async (data) => {
    try {

      setSaveLoading(true);
      await RecommendationService.updateRecommendation(id, data);

      history.push('/babyweight/recommendation');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }


  const handleDelete = async () => {
    try {
      const respuesta = confirm(t('general.label.deleteRegister'));
      if (respuesta === true) {
        const data = await RecommendationService.deleteRecommendation(id);
        toast.success(t('babyWeight.recommendation.buttons.deleteSuccess'));
        history.push('/babyweight/recommendation');
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImageRequest = async (file) => {
    const formData = new FormData();
    const prefix = 'file_';
    const microTime = new Date().getTime() * 1000 + performance.now();
    const extension = file.name.split('.').pop();
    const randomName = `${prefix}${microTime}.${extension}`;
    formData.append('file', new File([file], randomName, { type: file.type }));

    try {
      dispatch(setLoading(true));
      const response = await api.uploadFile(formData);
      setUploadImage(response.data.file);
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    StageService
      .getStage()
      .then((response) => {
        setStage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(setLoading(true));
    RecommendationService
      .getRecommendationById(id)
      .then((response) => {
        const stageId = response.data.stageId;
        const uploadImage = response.data.file;
        setStageId(stageId)
        setUploadImage(uploadImage)
        formik.setValues(
          {
            url: response.data.url,
            fileId: response.data.fileId,
            file: response.data.file,
            stageId: response.data.stageId,
            stage: response.data.stage,
            content: response.data.content
          },
          true
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">
              {t('babyWeight.recommendation.edit')}
            </h3>
            <div className="mr-1 card-tools">
              <Button
                type="submit"
                block
                onClick={handleDelete}
                className="btn btn-danger"
              >
                <i className="icon-button fa fa-trash"></i>
              </Button>
            </div>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">

                  <div className="col-sm-6">
                    <label htmlFor="inputName">
                      {t(
                        'babyWeight.recommendation.stageId'
                      )}
                    </label>
                    {stage &&
                      stage.length > 0 ? (
                      <div className="inputStatus">
                        <SelectState2
                          items={
                            stage?.map((val)=>({
                              ...val,
                              nombre: val?.id
                            }))
                          }
                          optionTitle="name"
                          onChange={(
                            item
                          ) =>
                            
                            {
                              setStageId(item.id);
                            formik?.setFieldValue("stageId", item.id);
                            }
                            
                          }
                          value={
                            formik
                              .values
                              .stageId
                          }
                          formik={formik}
                          formikFieldProps={formik.getFieldProps(
                            'stageId'
                          )}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group row  mt-3 ml-2" >
                    <label>
                      {t('babyWeight.recommendation.url')}
                    </label>
                    <Input
                      value={
                        formik.values.url
                      }
                      formik={formik}
                      formikFieldProps={formik.getFieldProps(
                        'url'
                      )}
                      type="text"
                      placeholder={t(
                        'babyWeight.recommendation.url'
                      )}
                    />
                  </div>

                  <div className="col-sm-6">
                    <ImageUpload
                      id="myImage"
                      label={t(
                        'babyWeight.recommendation.fileId'
                      )}
                      image={
                        uploadImage
                      }
                      witdh={515}
                      height={515}
                      uploadImage={
                        uploadImageRequest
                      }
                    />
                  </div>
                  <div className="form-group row mt-5">
                    <label
                      htmlFor="inputName"
                      className="col-sm-2 col-form-label"
                    >
                      {t('babyWeight.recommendation.content')}
                    </label>
                    <div className="col-sm-10">
                      <Textarea
                        placeholder={t('babyWeight.recommendation.content')}
                        type="text"
                        value={
                          formik.values.content
                        }
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('content')}
                      />
                    </div>
                  </div>



                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/babyweight/weighing`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('babyWeight.recommendation.buttons.create')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRecommendation