import React, {useState, useEffect, useMemo} from 'react';
import {ContentHeader, Button, FilterComponent} from '@components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import * as adviceConsejoService from '../../services/adviceConsejos';
import {prettyOnlyDateOfBirth, prettyOnlyDate} from '../../utils/pretty-date';
import {
    customStyles,
    paginationComponentOptions
} from '../../utils/table-config';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const locale = {
    format: 'MM/DD/YYYY',
    separator: ' - ',
    applyLabel: 'Filtrar',
    cancelLabel: 'Cancelar',
    fromLabel: 'From',
    toLabel: 'To',
    customRangeLabel: 'Custom',
    weekLabel: 'W',
    daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ],
    firstDay: 1
};

const ranges = {
    Hoy: [moment(), moment()],
    Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ultimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Ultimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'Últtimo mes': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ]
};

const headerButtons = [
    {
        text: 'Agregar nuevo consejo',
        urlSlug: '/adviceconsejos/create'
    }
];

const AdviceConsejosList = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [advices, setAdvices] = useState([]);
    const loading = useSelector((state) => state.ui.loading);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days'));
    const [endDate, setEndDate] = useState(moment());

    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: <b>{t('advices.table.name')}</b>,
            selector: (row) => (row.name === null ? '-' : row.name),
            sortable: true
        },
        {
            name: <b>{t('advices.table.content')}</b>,
            selector: (row) => (row.content === null ? '-' : row.content),
            sortable: true
        },
        {
            name: <b>{t('advices.table.status')}</b>,
            selector: (row) => (row.status === 0 ? "Inactivo" :"Activo"),
            sortable: true
        },
        {
            name: '',
            cell: (row) => (
                <Button
                    className="m-1 btn btn-danger"
                    onClick={() => handleButtonClick(row)}
                    id={row.id}
                >
                    <i className="icon-button fa fa-edit"></i>
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ]);

    // Hooks para el filtro de la tabla
    const [filterCode, setFilterCode] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = advices.filter(
        (item) => item.name
    );

    const handleCallback = async (start, end, label) => {
        setStartDate(start);
        setEndDate(end);
    };

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterAuthor) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterAuthor('');
            }

            if (filterCode) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterCode('');
            }
        };

        return (
            <div className="d-flex p-2">
               
                
            </div>
        );
    }, [filterAuthor, filterCode, resetPaginationToggle]);

    const handleButtonClick = (item) => {
        history.push(`/adviceconsejos/edit/${item.id}`);
    };

    useEffect(() => {
        dispatch(setLoading(true));
        adviceConsejoService
            .getAdvice()
            .then((response) => {
            
                setAdvices(response.data);
                dispatch(setLoading(false));
            })
            .catch((err) => {
               
                dispatch(setLoading(false));
            });
    }, []);

    console.log("=>advices",advices)
    return (
        <>
            <div id="news-list">
                <ContentHeader title={t('menusidebar.label.advice.label')} buttons={headerButtons} />

                {advices && advices.length > 0 ? (
                    <DataTable
                        data={filteredItems}
                        customStyles={customStyles}
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noDataComponent="No se encuentran registros"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                ) : (
                    <div>
                        <h1 className="text-center">
                            {t('general.label.noRegisters')}
                        </h1>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdviceConsejosList;
