import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, Select } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as WeighingService from '../../../services/babyWeight/weighing';



const EditWeighing = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false)
  const { id } = useParams();

  const [genderName, setGender] = useState();
  const [gender, setIdGender] = useState('');

  const dataGender = [
    { id: 1, name: 'Masculino', value: 1 },
    { id: 2, name: 'Femenino', value: 2 }
  ]

  useEffect(() => {
    setGender(dataGender)
  }, [])

  const formik = useFormik({
    initialValues: {
      gender: '',
      Age: '',
      Max_Weight: '',
      Min_Weight: ''
    },
    validationSchema: Yup.object({

      // gender: Yup.string()
      //   .required(t('validationSchema.required')),
      Age: Yup.string()
        .required(t('validationSchema.required')),
      Max_Weight: Yup.number()
        .required(t('validationSchema.required')),
      Min_Weight: Yup.number()
        .required(t('validationSchema.required')),
    }),
    onSubmit: (data) => {
      SendWeighing(data);

    }
  });

  const SendWeighing = async (data) => {

    try {
      const adviceInput =
      {
        gender: gender,
        Age: Number(data.Age),
        Max_Weight: data.Max_Weight,
        Min_Weight: data.Min_Weight

      }
      setSaveLoading(true);
      await WeighingService.updateWeighing(id, adviceInput);


      history.push('/babyweight/weighing');
    } catch (error) {
      setSaveLoading(false);
      dispatch(setLoading(false));
      const errorMessage = error.response.data.errors[0]
      toast.error(errorMessage);
    }
  }

  const handleDelete = async () => {
    try {
      const respuesta = confirm(t('general.label.deleteRegister'));
      if (respuesta === true) {
        const data = await WeighingService.deleteWeighing(id);
        toast.success(t('babyWeight.weighing.buttons.deleteSuccess'));
        history.push('/babyweight/weighing');
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    WeighingService
      .getWeighingById(id)
      .then((response) => {
        const idGender = response.data.gender;
        setIdGender(idGender)

        formik.setValues(
          {
            Age: response.data.age,
            Max_Weight: response.data.max_Weight,
            Min_Weight: response.data.min_Weight
          },
          true
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header -p2">
            <h3 className="text-danger font-weight-bold">
              {t('babyWeight.weighing.edit')}
            </h3>
            <div className="mr-1 card-tools">
              <Button
                type="submit"
                block
                onClick={handleDelete}
                className="btn btn-danger"
              >
                <i className="icon-button fa fa-trash"></i>
              </Button>
            </div>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group col-md-6 row">

                    <label
                      htmlFor="inputName"
                      className="col-sm-2 col-form-label"
                    >
                      {t('babyWeight.weighing.gender')}
                    </label>
                    {genderName && genderName.length > 0 ? (
                      <div className="col-sm-8">
                        <Select
                          items={genderName}
                          optionTitle="name"
                          onChange={(item) => setIdGender(item.value)}
                          value={gender}
                          formik={formik}
                          formikFieldProps={formik.getFieldProps('gender')} />
                      </div>
                    ) : null}


                  </div>
                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-2 col-form-label"
                    >
                      {t('babyWeight.weighing.age')}
                    </label>
                    <div className="col-sm-4">
                      <Input
                        placeholder={t('babyWeight.weighing.age')}
                        type="text"
                        value={formik.values.Age}
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('Age')}
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.weighing.Max_Weight')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('babyWeight.weighing.Max_Weight')}
                        type="text"
                        value={formik.values.Max_Weight}
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('Max_Weight')}
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-6 row">
                    <label
                      htmlFor="inputName"
                      className="col-sm-4 col-form-label"
                    >
                      {t('babyWeight.weighing.Min_Weight')}
                    </label>
                    <div className="col-sm-6">
                      <Input
                        placeholder={t('babyWeight.weighing.Min_Weight')}
                        type="text"
                        value={formik.values.Min_Weight}
                        formik={formik}
                        formikFieldProps={formik.getFieldProps('Min_Weight')}
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div className="row p-2 pt-4">
                <div className="col-2">
                  <Link
                    to={`/babyweight/weighing`}
                    className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                </div>
                <div className="col-sm-2">
                  <Button type="submit" block
                    isLoading={isSaveLoading} className="btn btn-danger">
                    {t('babyWeight.weighing.buttons.edit')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditWeighing