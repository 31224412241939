import React, {useState, useEffect, useRef} from 'react';
import {Button, Input, ComboBox, Textarea, Checkbox} from '@components';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useHistory, Link, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {prettyOnlyDateOfBirth} from '../../utils/pretty-date';
import {setLoading} from '@app/store/reducers/ui';
import * as Yup from 'yup';
import * as adviceConsejoService from '../../services/adviceConsejos';

const EditAdvice = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();
    const {id} = useParams();

    const [status, setStatus] = useState(false);

    const formik = useFormik({
        initialValues: {
            content: '',
            name: ''
        },
        validationSchema: Yup.object({
            content: Yup.string().required(
                t('validationSchema.detailsNewsRequired')
            ),
            name: Yup.string().required(t('validationSchema.infoNewRequired'))
        }),
        onSubmit: (data) => {
            SendAdvice(data);
        }
    });

    const SendAdvice = async (data) => {
        const adviceInput = {
            content: data.content,
            name: data.name,
            Status: status ? 1 : 0
        };
        try {
            await adviceConsejoService.updateAdvice(id, adviceInput);
            history.push('/adviceconsejos');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error.response.data.errors[0];
            toast.error(errorMessage);
        }
    };

    const handleDelete = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'));
            if (respuesta === true) {
                const data = await adviceConsejoService.deleteAdvice(id);
                toast.success(t('advices.buttons.deleteSuccess'));
                history.push('/adviceconsejos');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(setLoading(true));
        adviceConsejoService
            .getAdviceById(id)
            .then((response) => {
                const estatus = response.data.status === 0 ? false : true;
                setStatus(estatus);
                formik.setValues(
                    {
                        name: response.data.name,
                        content: response.data.content
                        //status: response.data.status,
                    },
                    true
                );
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            });
    }, []);

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">
                            {t('advices.edit')}
                        </h3>
                        <div className="mr-1 card-tools">
                            <Button
                                type="submit"
                                block
                                onClick={handleDelete}
                                className="btn btn-danger"
                            >
                                <i className="icon-button fa fa-trash"></i>
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <form
                            className="form-horizontal"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('advices.name')}
                                        </label>
                                        <div className="col-sm-4">
                                            <Input
                                                placeholder={t('advices.name')}
                                                type="text"
                                                value={formik.values.name}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'name'
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('advices.content')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t(
                                                    'advices.content'
                                                )}
                                                type="text"
                                                value={formik.values.content}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'content'
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label>{t('advices.status')}</label>
                                        <Checkbox
                                            checked={status}
                                            onChange={setStatus}
                                            label={status? "Activo":"inactivo"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/adviceconsejos`}
                                        className="btn text-danger"
                                    >
                                        {' '}
                                        {t('general.buttons.cancel')}{' '}
                                    </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        type="submit"
                                        block
                                        isLoading={isSaveLoading}
                                        className="btn btn-danger"
                                    >
                                        {t('advices.buttons.edit')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAdvice;
