import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import './MenuSidebar.css';

export const MENU_SUPER_ADMIN = [
    {
        name: 'menusidebar.label.reports',
        navIcon: 'fa-tachometer-alt',
        path: '/'
    },
    {
        name: 'menusidebar.label.users',
        navIcon: 'fa-users',
        path: '/users'
    },
    {
        name: 'menusidebar.label.clients',
        navIcon: 'fa-users',
        path: '/clients'
    },
    {
        name: 'menusidebar.label.notifications',
        navIcon: 'fa-paper-plane',
        path: '/notifications'
    },
    {
        name: 'menusidebar.label.categories',
        navIcon: 'fa-cog',
        path: '/categories'
    },
    {
        name: 'menusidebar.label.news',
        navIcon: 'fa-newspaper',
        children: [
            {
                name: 'menusidebar.label.seeNews',
                path: '/news'
            },
            {
                name: 'menusidebar.label.createNews',
                path: '/news/create'
            },
            {
                name: 'menusidebar.label.tags',
                path: '/news/categories'
            }
        ]
    },
    {
        name: 'menusidebar.label.ticketScan',
        navIcon: 'fa-barcode',
        children: [
            {
                name: 'menusidebar.label.seeTicket',
                path: '/tickets'
            },
            {
                name: 'menusidebar.label.pointsClient',
                path: '/ticketScan/Points'
            }
        ]
    },
    {
        name: 'menusidebar.label.store.label',
        navIcon: 'fa-store',
        children: [
            {
                name: 'menusidebar.label.store.stores',
                path: '/stores'
            },
            {
                name: 'menusidebar.label.store.create',
                path: '/stores/create'
            }
        ]
    },
    {
        name: 'menusidebar.label.donations',
        navIcon: 'fa-heart',
        path: '/donations'
    },
    {
        name: 'menusidebar.label.rewards',
        navIcon: 'fa-gift',
        children: [
            {
                name: 'menusidebar.label.seeRewards',
                path: '/rewards'
            },
            {
                name: 'menusidebar.label.createRewards',
                path: '/rewards/create'
            },
            {
                name: 'menusidebar.label.redeem',
                path: '/rewards/redeem'
            }
        ]
    },
    {
        name: 'menusidebar.label.promotion.label',
        navIcon: 'fa-percent',
        children: [
            {
                name: 'menusidebar.label.promotion.promotions',
                path: '/promotions'
            },
            {
                name: 'menusidebar.label.promotion.reports',
                path: '/promotions/reports'
            },
            {
                name: 'menusidebar.label.promotion.create',
                path: '/promotions/create'
            }
        ]
    },
    {
        name: 'Peso bebés',
        navIcon: 'fa fa-balance-scale',
        children: [
            {
                name: 'babyWeight.weighing.label',
                path: '/babyweight/weighing'
            },
            {
                name: 'babyWeight.weighing.create',
                path: '/babyweight/weighing/create'
            },
            {
                name: 'babyWeight.stage.label',
                path: '/babyweight/stage'
            },
            {
                name: 'babyWeight.stage.create',
                path: '/babyweight/stage/create'
            },
            {
                name: 'babyWeight.recommendation.label',
                path: '/babyweight/recommendation'
            },
            {
                name: 'babyWeight.recommendation.create',
                path: '/babyweight/recommendation/create'
            }
           
        ]
    },
    // {
    //     name: 'menusidebar.label.diaryBaby.label',
    //     navIcon: 'fa fa-info-circle',
    //     children: [
    //         {
    //             name: 'menusidebar.label.diaryBaby.diaryBabys',
    //             path: '/diaryBabys/'
    //         },
    //         {
    //             name: 'menusidebar.label.diaryBabys.create',
    //             path: '/diaryBabys/create'
    //         }
    //     ]
    // },
    {
        name: 'menusidebar.label.categoryBaby.label',
        navIcon: 'fa fa-info-circle',
        children: [
            {
                name: 'menusidebar.label.categoryBaby.categoryBabys',
                path: '/categorybabys/'
            },
            {
                name: 'menusidebar.label.categoryBaby.create',
                path: '/categorybabys/create'
            }
        ]
    },
    {
        name: 'menusidebar.label.advice.label',
        navIcon: 'fa fa-info-circle',
        children: [
            {
                name: 'menusidebar.label.advice.advices',
                path: '/adviceconsejos/'
            },
            {
                name: 'menusidebar.label.advice.create',
                path: '/adviceconsejos/create'
            }
        ]
    },
    {
        name: 'menusidebar.label.config',
        navIcon: 'fa-cog',
        children: [
            {
                name: 'menusidebar.label.about',
                path: '/configuration/system'
            }
        ]
    },
    {
        name: 'Preguntas frecuentes',
        path: '/faqs'
    },
    {
        name: 'Términos y condiciones',
        path: '/terms'
    }
];

export const MENU_ADMIN = [
    {
        name: 'menusidebar.label.reports',
        navIcon: 'fa-tachometer-alt',
        path: '/'
    },
    {
        name: 'menusidebar.label.clients',
        navIcon: 'fa-users',
        path: '/clients'
    },
    {
        name: 'menusidebar.label.notifications',
        navIcon: 'fa-paper-plane',
        path: '/notifications'
    },
    {
        name: 'menusidebar.label.news',
        navIcon: 'fa-newspaper',
        children: [
            {
                name: 'menusidebar.label.seeNews',
                path: '/news'
            },
            {
                name: 'menusidebar.label.createNews',
                path: '/news/create'
            },
            {
                name: 'menusidebar.label.tags',
                path: '/news/categories'
            }
        ]
    },
    {
        name: 'menusidebar.label.ticketScan',
        navIcon: 'fa-barcode',
        children: [
            {
                name: 'menusidebar.label.seeTicket',
                path: '/tickets'
            },
            {
                name: 'menusidebar.label.pointsClient',
                path: '/ticketScan/Points'
            }
        ]
    },
    {
        name: 'menusidebar.label.donations',
        navIcon: 'fa-heart',
        path: '/donations'
    },
    {
        name: 'menusidebar.label.rewards',
        navIcon: 'fa-gift',
        children: [
            {
                name: 'menusidebar.label.seeRewards',
                path: '/rewards'
            },
            {
                name: 'menusidebar.label.createRewards',
                path: '/rewards/create'
            },
            {
                name: 'menusidebar.label.redeem',
                path: '/rewards/redeem'
            }
        ]
    },
    {
        name: 'menusidebar.label.promotion.label',
        navIcon: 'fa-percent',
        children: [
            {
                name: 'menusidebar.label.promotion.promotions',
                path: '/promotions'
            },
            {
                name: 'menusidebar.label.promotion.reports',
                path: '/promotions/reports'
            },
            {
                name: 'menusidebar.label.promotion.create',
                path: '/promotions/create'
            }
        ]
    },
    {
        name: 'menusidebar.label.advice.label',
        navIcon: 'fa fa-info-circle',
        children: [
            {
                name: 'menusidebar.label.advice.advices',
                path: '/adviceConsejo'
            }
        ]
    },
];

const MenuSidebar = () => {
    const user = useSelector((state) => state.auth.currentUser);
    const getRol = JSON.parse(localStorage.getItem('datauser'));
    const rolUser = getRol.isSuperAdmin;

    return (
        <aside className="main-sidebar   elevation-4">
            <Link to="/" className="brand-link brand-head">
                <div className="text-center">
                    <img
                        src="/img/huggiesnegativo.png"
                        alt="Hubbies Logo"
                        width="70%"
                        className="rounded"
                        style={{opacity: '.8', marginLeft: '11px'}}
                    />
                    <span className="m-2  txt-sidebar"></span>
                </div>
            </Link>
            <div className="sidebar">
                <nav
                    style={{
                        marginTop: '6px',
                        overflowY: 'hidden',
                        overflowX: 'hidden'
                    }}
                >
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                    >
                        {rolUser
                            ? MENU_SUPER_ADMIN.map((menuItem) => (
                                  <MenuItem
                                      key={menuItem.name}
                                      menuItem={menuItem}
                                  />
                              ))
                            : MENU_ADMIN.map((menuItem) => (
                                  <MenuItem
                                      key={menuItem.name}
                                      menuItem={menuItem}
                                  />
                              ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default MenuSidebar;
