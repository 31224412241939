import Api from '../utils/axios';
const api = new Api();

export const getStores = async () => {
    const stores = await api.getStores();
    return stores;
};

export const getStoreById = async (id) => {
    const storesbyid = await api.getStoreById(id);
    return storesbyid;
};

export const deleteStore = async (id) => {
    const deletestore = await api.deleteStore(id);
    return deletestore.data;
};

export const updateStore = async (id, data) => {
    const updatestore = await api.updateStore(id, data);
    return updatestore;
};

export const createStore = async (data) => {
    const resultCreate = await api.createStore(data);
    return resultCreate;
};

export const getProductsByStores = async (id) => {
    const stores = await api.getProductsByStores(id);
    return stores;
};

export const getProducts = async () => {
    const stores = await api.getProducts();
    return stores;
};

export const deleteProductOnStore = async (prod, id) => {
    const deleteproductonstore = await api.deleteProductOnStore(prod, id);
    return deleteproductonstore.data;
};

export const addProductToStore = async (data) => {
    const resultCreate = await api.addProductToStore(data);
    return resultCreate;
};