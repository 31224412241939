/* eslint-disable react/no-unstable-nested-components */
import React, {useState, useEffect, useMemo} from 'react';
import {Button, Input} from '@components';
import {useFormik} from 'formik';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import {
    customStyles,
    paginationComponentOptions
} from '../../../utils/table-config';
import * as usersService from '../../../services/rewords';

const PackageList = [
    {name: 'name', label: 'Nombre'},
    {name: 'product_type', label: 'Tipo'},
    {name: 'unit_type', label: 'Tipo de unidad'},
    {name: 'package_content', label: 'Contenido'},
    {name: 'amount_pkg', label: 'Monto del paquete'},
    {name: 'height', label: 'Alto', type: 'number'},
    {name: 'width', label: 'Ancho', type: 'number'},
    {name: 'length', label: 'Largo', type: 'number'},
    {name: 'weight', label: 'Peso', type: 'number'},
    {name: 'real_weight', label: 'Peso real'},
    {name: 'bill_weight', label: 'Valor'},
    {name: 'default_pkg', label: 'Peso por defecto'}
];
const ListPackages = () => {
    const [packages, setPackages] = useState([]);
    const loading = useSelector((state) => state.ui.loading);
    const [open, setOpen] = useState(false);
    const [packageData, setPackageData] = useState(null);
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: '',
            cell: (row) => (
                <Button
                    className="mr-1 btn btn-danger"
                    onClick={() => {
                        setOpen(true);
                        setPackageData(row);
                    }}
                    id={row.id}
                >
                    <i className="icon-button fa fa-eye" />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
        {
            name: <b>Nombre</b>,
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: <b>Producto</b>,
            selector: (row) => row.product_type,
            sortable: true
        },
        {
            name: <b>Unidad</b>,
            selector: (row) => row.unit_type,
            sortable: true
        },
        {
            name: <b>Contenido</b>,
            selector: (row) => row.package_content,
            sortable: true
        }
    ]);

    useEffect(() => {
        dispatch(setLoading(true));
        usersService
            .getListPackages()
            .then((response) => {
                setPackages(response?.data?.data);
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            name: packageData?.name || '',
            product_type: packageData?.product_type || '',
            unit_type: packageData?.unit_type || '',
            package_content: packageData?.package_content || '',
            amount_pkg: packageData?.amount_pkg || '',
            height: packageData?.height || '',
            width: packageData?.width || '',
            length: packageData?.length || '',
            weight: packageData?.weight || '',
            real_weight: packageData?.real_weight || '',
            volumetric_weight: packageData?.volumetric_weight || '',
            bill_weight: packageData?.bill_weight || '',
            default_pkg: packageData?.default_pkg || ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Campo requerido.'),
            product_type: Yup.string().required('Campo requerido.'),
            unit_type: Yup.string().required('Campo requerido.'),
            package_content: Yup.string().required('Campo requerido.'),
            amount_pkg: Yup.string().required('Campo requerido.'),
            height: Yup.string().required('Campo requerido.'),
            width: Yup.string().required('Campo requerido.'),
            length: Yup.string().required('Campo requerido.'),
            weight: Yup.string().required('Campo requerido.'),
            real_weight: Yup.string().required('Campo requerido.'),
            volumetric_weight: Yup.string().required('Campo requerido.'),
            bill_weight: Yup.string().required('Campo requerido.')
        }),
        onSubmit: (values) => {
            dispatch(setLoading(true));
            usersService
                .createPackage(1, values)
                .then(() => {
                    setOpen(false);
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    return (
        <div id="news-list">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-5">
                        <h1 className="text-danger font-weight-bold">
                            Paquetes
                        </h1>
                    </div>
                </div>
            </div>{' '}
            {packages && packages.length > 0 ? (
                <DataTable
                    data={packages}
                    customStyles={customStyles}
                    progressPending={loading}
                    subHeader
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    columns={columns}
                />
            ) : (
                <div>
                    <h1 className="text-center">No se encuentran registros</h1>
                </div>
            )}
            <Modal centered show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger font-weight-bold">
                        {packageData?.id ? 'Detalle de' : 'Nuevo'} paquete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="form-row mt-3 d-flex align-items-center">
                            {PackageList?.map((val, k) => (
                                <div key={k} className="form-group col-md-12">
                                    <Input
                                        placeholder={val?.label}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps(
                                            val?.name
                                        )}
                                        type={val?.type || 'text'}
                                        value={formik?.values[val?.name]}
                                    />
                                </div>
                            ))}
                        </div>
                    </form>
                </Modal.Body>
                {!packageData?.id ? (
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => formik?.handleSubmit()}
                        >
                            Crear
                        </Button>
                    </Modal.Footer>
                ) : null}
            </Modal>
        </div>
    );
};

export default ListPackages;
