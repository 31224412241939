import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from '@components';

const ContentHeader = ({ title, buttons }) => {
    const history = useHistory();

    const routeTo = (urlSlug) => {
        history.push(urlSlug)
    }

    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-5">
                        <h1 className="text-danger font-weight-bold" >{title}</h1>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center justify-content-end gap-3">
                        {buttons && buttons.length > 0 ?
                            buttons.map((data, index) => (
                                <Button style={{ width: "auto"}} className='btn btn-success mt-0 mr-3' key={'content-button-' + index} block onClick={() => routeTo(data.urlSlug)}>
                                    {data.text}
                                </Button>
                            ))
                            : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContentHeader;
