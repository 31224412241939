import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, FilterComponent} from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import { setLoading } from '@app/store/reducers/ui';
import * as Yup from 'yup';
import * as storeService from '../../services/stores';
import { customStylesTableStores, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';


const EditStore = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();
    const {id} = useParams();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [productOnStore, setProductsOnStore] = useState([]);
    const [products, setProducts] = useState([]);
    const [ids, setIds] = useState([]);
    const columns = useMemo(() => [
        {
            name: <b>{t('stores.list.table.columns.id')}</b>,
            selector: row => row.id,
            sortable: false,
            style: {
                '&': {
                    flexBasis: 'unset',
                    minWidth: '60px',
                    maxWidth: 'fit-content'
                },
            },
        },
        {
            name: <b>{t('stores.list.table.columns.product')}</b>,
            selector: row => `${row?.upc} - ${row?.descripcion} - ${row?.ticket || ""}`,
            sortable: false,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleDeleteProductOnStore(row?.id)} id={row.id} >{t('stores.button.delete')}</Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const columnsAllProducts = useMemo(() => [
        {
            name: <b>{t('stores.list.table.columns.id')}</b>,
            selector: row => row?.id,
            sortable: false,
            style: {
                '&': {
                    flexBasis: 'unset',
                    minWidth: '60px',
                    maxWidth: 'fit-content'
                },
            },
        },
        {
            name: <b>{t('stores.list.table.columns.product')}</b>,
            selector: row => `${row?.upc} - ${row?.descripcion} - ${row?.ticket || ""}`,
            sortable: false,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleAddProductToStore(row?.id)} id={row.id} >{t('stores.button.add')}</Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        enabledReinitialized: true,
        validationSchema: Yup.object({
            name: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (data) => {
            sendPromotion(data);
        }
    });

    const sendPromotion = async (data) => {

        const promotion = {
            nombre: data.name
        };

        try {
            await storeService.updateStore(id, promotion);
            toast.success(t('stores.updatedsuccess'));
            history.push('/stores');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    };

    const handleDelete = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                await storeService.deleteStore(id);
                toast.success(t('stores.deletesuccess'));
                history.push('/stores');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    };

    const handleDeleteProductOnStore = async (idProd) => {
        try {
                await storeService.deleteProductOnStore(idProd, id)
                .then(response => {
                    console.log(response);
                    toast.success("Producto eliminado.");
                    getProductsOnStore();
                })
                .catch(eerrorrr => {
                    dispatch(setLoading(false));
                    const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde";
                    toast.error(errorMessage);
                });
                // getProductsOnStore();
                // toast.success(t('stores.deleteProduct'));
        } catch (err) {
            console.log(err)
        }
    };

    const handleAddProductToStore = async (idProd) => {
        try {
            await storeService.addProductToStore({
                'productsId': parseInt(idProd),
                'tiendasId': parseInt(id)
            })
            .then(response => {
                console.log(response);
                toast.success("Prodcuto agregado.");
                getProductsOnStore();

            })
            .catch(err => {
                const errorMessage = error?.response?.data?.errors[0] || "Lo sentimos intenta mas tarde";
                toast.error(errorMessage);
            });
        } catch (err) {
            console.log(err)
        }
    };

    const getProductsOnStore = async () => {
        try {
            await storeService.getProductsByStores(id)
            .then(response => {
                console.log(response);
                setIds(response.data?.map((v)=>v?.id));
                setProductsOnStore(response.data);
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            });
        } catch (err) {
            console.log(err)
        }
    };

    const getAllProducts = async (id) => {
        try {
            storeService.getProducts()
            .then(response => {
                console.log(response);
                setProducts(response.data);
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        dispatch(setLoading(true));
        storeService.getStoreById(id)
            .then(response => {
                console.log(response);
                formik.setFieldValue("name", response.data.nombre );
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })
        getProductsOnStore();
        getAllProducts();
    }, []);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterName) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterName('');
            }
        };

        return (
            <div className='d-flex p-2 col-12 justify-content-between align-items-end'>
                <div className="p-2 w-100">
                    <label
                        htmlFor="inputName"
                        className="col-sm-12 col-form-label"
                    >
                        {t('stores.create.input')}
                    </label>
                    <FilterComponent onFilter={e => setFilterName(e.target.value)} filterText={filterName} />
                </div>
                <div className="p-2" style={{}}>
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterName, resetPaginationToggle]);

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('stores.edit')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                type="submit" block
                                onClick={handleDelete}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group row">
                                        <div className="col-sm-6">
                                            <label
                                                htmlFor="inputName"
                                                className="col-sm-12 col-form-label"
                                            >
                                                {t('stores.create.input')}
                                            </label>
                                            <Input
                                                value={formik?.values?.name}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                                type="text"
                                            />
                                            <div className="card-header -p2">
                                                <h3 className="text-danger font-weight-bold titleSectionProduct">{t('stores.all')}</h3>
                                            </div>
                                            <DataTable
                                                data={productOnStore}
                                                customStyles={customStylesTableStores}
                                                // progressPending={loading}
                                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                                noDataComponent="No se encuentran registros"
                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                columns={columns}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                        <   div className="card-header -p2">
                                                <h3 className="text-danger font-weight-bold titleSectionProduct">{t('stores.add')}</h3>
                                            </div>
                                            <DataTable
                                                data={filterName ? products?.filter((val)=>val?.descripcion?.toLowerCase().includes(filterName?.toLowerCase()))?.filter((v)=>!ids?.includes(v?.id)) : products?.filter((v)=>!ids?.includes(v?.id))}
                                                customStyles={customStylesTableStores}
                                                // progressPending={loading}
                                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                                noDataComponent="No se encuentran registros"
                                                pagination
                                                paginationComponentOptions={paginationComponentOptions}
                                                columns={columnsAllProducts}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/stores`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('tickets.label.modalText7')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditStore