import Api from '../utils/axios';
const api = new Api();

export const getDiaryBabys = async () => {
    const diaryBabys = await api.getDiaryBabys();
    return diaryBabys;
}

export const createDiaryBabys = async (data) => {
    const diaryBabys = await api.createDiaryBabys(data);
    return diaryBabys;
}

export const deleteDiaryBabys = async (id) => {
    const diaryBabys = await api.deleteDiaryBabys(id);
    return diaryBabys.data;
}

export const updateDiaryBabys = async (id, data) => {
    const diaryBabys = await api.updateDiaryBabys(id, data);
    return diaryBabys;
}

export const getDiaryBabysById = async (id) => {
    const diaryBabys = await api.getDiaryBabysById(id);
    return diaryBabys;
}