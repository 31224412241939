import {setLoading} from '@app/store/reducers/ui';
import {Button, ComboBox, ImageUpload, Input, Textarea} from '@components';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import * as benefitsService from '../../services/rewords';
import Api from '../../utils/axios';
import * as usersService from '../../services/rewords';

const PackageList = [
    {name: 'name', label: 'Nombre'},
    {name: 'product_type', label: 'Tipo'},
    {name: 'unit_type', label: 'Tipo de unidad'},
    {name: 'package_content', label: 'Contenido'},
    {name: 'amount_pkg', label: 'Monto del paquete', type: 'number'},
    {name: 'height', label: 'Alto', type: 'number'},
    {name: 'width', label: 'Ancho', type: 'number'},
    {name: 'length', label: 'Largo', type: 'number'},
    {name: 'weight', label: 'Peso', type: 'number'},
    {name: 'real_weight', label: 'Peso real'},
    {name: 'bill_weight', label: 'Valor'},
    {name: 'default_pkg', label: 'Peso por defecto'},
    {name: 'volumetric_weight', label: 'Peso volumetrico'},
];

const EditRewards = () => {
    const api = new Api();
    const history = useHistory();
    const [rewords, setRewards] = useState(false);
    const [reword, setReward] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [loading, setloading] = useState(false);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [uploadImage, setUploadImage] = useState();
    const [refresh, setRefresh] = useState();
    const [t] = useTranslation();
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            name: '',
            // imageId: null,
            value: 0,
            description: '',
            categoryId: 0,
            subCategoryId: 0,
            category: null,
            subCategory: null,
            status: 1,
            stock: 0,
            imageId: 1
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.rewardName')),
            value: Yup.number().required(t('validationSchema.rewardValue')),
            description: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.rewardDescription')),
            status: Yup.number(),
            stock: Yup.number().required(t('validationSchema.rewardStock'))
        }),
        onSubmit: (values) => {
            sendDataReword(values);
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        benefitsService
            .getRewordsById(id)
            .then((response) => {
                formik.setValues({...response.data});
                setReward(response.data);
                api.getCategories()
                    .then((response) => {
                        setCategories(response.data);
                        api.getSubCategories()
                            .then((response) => {
                                setSubCategories(response.data);
                                dispatch(setLoading(false));
                            })
                            .catch((error) => {
                                dispatch(setLoading(false));
                            });
                    })
                    .catch((error) => {
                        dispatch(setLoading(false));
                    });
            })
            .catch((error) => {
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        if (formik?.values?.categoryId) {
            api.getSubCategoriesByCategory(formik?.values?.categoryId)
                .then((response) => {
                    dispatch(setLoading(false));
                    setSubCategories(response.data);
                })
                .catch((error) => {
                    dispatch(setLoading(false));
                });
        }
        dispatch(setLoading(true));
    }, [formik?.values?.categoryId]);

    const uploadImageRequest = async (file) => {
        const formData = new FormData();
        const prefix = 'file_';
        const microTime = new Date().getTime() * 1000 + performance.now();
        const extension = file.name.split('.').pop();
        const randomName = `${prefix}${microTime}.${extension}`;

        formData.append('file', new File([file], randomName, { type: file.type }));
        setReward({});
        try {
            dispatch(setLoading(true));
            const response = await api
                .uploadFileReward(id, formData)
                .then(() => {
                    benefitsService
                        .getRewordsById(id)
                        .then((response) => {
                            formik.setValues({...response.data});
                            setReward(response.data);
                            api.getCategories()
                                .then((response) => {
                                    setCategories(response.data);
                                    api.getSubCategories()
                                        .then((response) => {
                                            setSubCategories(response.data);
                                            dispatch(setLoading(false));
                                        })
                                        .catch((error) => {
                                            dispatch(setLoading(false));
                                        });
                                })
                                .catch((error) => {
                                    dispatch(setLoading(false));
                                });
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
            const newData = formik.values;
            setUploadImage(response.data.file);
            newData.image = response.data.file;
            formik.setValues({...newData}, true);
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
        }
    };

    const sendDataReword = async (data) => {
        // if (uploadImage != null) data.imageId = data.image.id;

        try {
            await benefitsService.updateRewords(id, {
                id: data?.id,
                name: data?.name,
                value: data?.value,
                description: data?.description,
                categoryId: data?.categoryId,
                subCategoryId: data?.subCategoryId,
                status: 1,
                stock: data?.stock,
                imageId: 1
            });
            setSaveLoading(true);
            setSaveLoading(false);
            toast.success(t('rewards.label.rewardEdited'));
            history.push('/rewards');
        } catch (error) {
            setSaveLoading(false);
            setReward(false);
            const errorMessage = error.response.data.errors[0];
            toast.error(errorMessage);
        }
    };

    const handleCategory = (item) => {
        const newData = formik.values;
        newData.category = item;
        newData.categoryId = item.id;
        newData.subCategory = null;
        newData.subCategoryId = null;
        formik.setValues({...newData}, true);
    };

    const handleSubCategory = (item) => {
        const newData = formik.values;
        newData.subCategory = item;
        newData.subCategoryId = item ? item.id : null;
        formik.setValues({...newData}, true);
    };

    const handleDeleteReword = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'));
            if (respuesta === true) {
                const data = await benefitsService.deleteReword(id);
                toast.success(t('rewards.label.rewardDeleted'));
                history.push('/rewards');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDeleteFile = async (idDelete) => {
        dispatch(setLoading(true));
        api.delUploadFileReward(idDelete)
            .then(() => {
                setReward({});
                dispatch(setLoading(false));
                benefitsService
                    .getRewordsById(id)
                    .then((response) => {
                        formik.setValues({...response.data});
                        setReward(response.data);
                        api.getCategories()
                            .then((response) => {
                                setCategories(response.data);
                                api.getSubCategories()
                                    .then((response) => {
                                        setSubCategories(response.data);
                                        dispatch(setLoading(false));
                                    })
                                    .catch((error) => {
                                        dispatch(setLoading(false));
                                    });
                            })
                            .catch((error) => {
                                dispatch(setLoading(false));
                            });
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const formikCreate = useFormik({
        initialValues: {
            name: 0,
            product_type: '',
            unit_type: '',
            package_content: '',
            amount_pkg: '',
            height: '',
            width: '',
            length: '',
            weight: '',
            real_weight: '',
            volumetric_weight: '',
            bill_weight: '',
            default_pkg: '',
            imageId: 1
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Campo requerido.'),
            product_type: Yup.string().required('Campo requerido.'),
            unit_type: Yup.string().required('Campo requerido.'),
            package_content: Yup.string().required('Campo requerido.'),
            amount_pkg: Yup.string().required('Campo requerido.'),
            height: Yup.string().required('Campo requerido.'),
            width: Yup.string().required('Campo requerido.'),
            length: Yup.string().required('Campo requerido.'),
            weight: Yup.string().required('Campo requerido.'),
            real_weight: Yup.string().required('Campo requerido.'),
            volumetric_weight: Yup.string().required('Campo requerido.'),
            bill_weight: Yup.string().required('Campo requerido.')
        }),
        onSubmit: (values) => {
            setloading(true);
            usersService
                .createPackage(id, values)
                .then(() => {
                    setloading(false);
                    setOpen(false);
                    toast.success("Paquete creado correctamente.");
                })
                .catch((error) => {
                    const errorMessage = error.response.data.errors[0];
                    toast.error(errorMessage);
                    setloading(false);
                });
        }
    });
    console.log("formik:", formikCreate?.values);
    return (
        <div>
            {/* <ContentHeader title={t('users.label.edit')} buttons={headerButtons} /> */}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">
                            {t('rewards.label.editReward')}
                        </h3>
                        <div className="mr-1 card-tools d-flex align-items-center justify-content-end">
                            <Button
                                isLoading={rewords}
                                type="submit"
                                onClick={handleDeleteReword}
                                className="btn btn-danger mr-3"
                            >
                                <i className="icon-button fa fa-trash" />
                            </Button>
                            <Button
                                isLoading={rewords}
                                type="submit"
                                onClick={() => setOpen(true)}
                                className="btn btn-danger"
                            >
                                Crear paquete de esta recompensa
                            </Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {reword ? (
                            <form
                                className="form-horizontal"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="form-group row">
                                            <label>
                                                {t('rewards.label.rewardName')}
                                            </label>
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'name'
                                                )}
                                                type="text"
                                                placeholder={t(
                                                    'rewards.label.rewardName'
                                                )}
                                                value={reword.name}
                                            />
                                        </div>

                                        <div className="form-group row">
                                            <label>
                                                {t(
                                                    'rewards.label.rewarddescription'
                                                )}
                                            </label>
                                            <Textarea
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'description'
                                                )}
                                                type="text"
                                                placeholder={t(
                                                    'rewards.label.rewarddescription'
                                                )}
                                                value={reword.description}
                                            />
                                        </div>

                                        <div className="form-group row">
                                            <label
                                                htmlFor="inputName"
                                                className="col-sm-3 col-form-label"
                                            >
                                                {t('rewards.label.category')}
                                            </label>

                                            {categories &&
                                            categories.length > 0 ? (
                                                <div className="col-sm-4">
                                                    <ComboBox
                                                        id="comboSub"
                                                        items={categories}
                                                        optionTitle="name"
                                                        onChange={
                                                            handleCategory
                                                        }
                                                        value={
                                                            formik.values
                                                                .categoryId
                                                        }
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps(
                                                            'categoryId'
                                                        )}
                                                        placeholder="Categoria"
                                                    />
                                                </div>
                                            ) : null}

                                            {formik.values.category &&
                                            formik.values.category
                                                .hasSubCategory ? (
                                                <>
                                                    <label
                                                        htmlFor="inputName"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        {t(
                                                            'rewards.label.subcategory'
                                                        )}
                                                    </label>
                                                    {subCategories &&
                                                    subCategories.length > 0 ? (
                                                        <div className="col-sm-3">
                                                                    <ComboBox
                                                                items={
                                                                            subCategories
                                                                        }
                                                                optionTitle="name"
                                                                onChange={
                                                                            handleSubCategory
                                                                        }
                                                                value={
                                                                            formik
                                                                                .values
                                                                                .subCategoryId
                                                                        }
                                                                formik={formik}
                                                                formikFieldProps={formik.getFieldProps(
                                                                            'subCategoryId'
                                                                        )}
                                                                        placeholder="Subcategoria"
                                                                messageOptionDefault="Ninguna"
                                                                hiddenOptionDefault={
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                    ) : (
                                                                <p>
                                                            No se encuentran
                                                            subcategorias
                                                                </p>
                                                            )}
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="form-group row">
                                            <label
                                                htmlFor="inputStock"
                                                className="col-sm-3 col-form-label"
                                            >
                                                {t(
                                                    'rewards.label.quantityExistence'
                                                )}
                                            </label>
                                            <div className="col-sm-4">
                                                <Input
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'stock'
                                                    )}
                                                    type="number"
                                                    value={reword.stock}
                                                    placeholder={t(
                                                        'rewards.label.quantityExistence'
                                                    )}
                                                />
                                            </div>

                                            <label
                                                htmlFor="inputValor"
                                                className="col-sm-2 col-form-label"
                                            >
                                                {t('rewards.label.valuePoints')}
                                            </label>
                                            <div className="col-sm-3">
                                                <Input
                                                    formik={formik}
                                                    value={reword.value}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'value'
                                                    )}
                                                    type="number"
                                                    placeholder={t(
                                                        'rewards.label.valuePoints'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="d-flex flex-wrap gap-3">
                                                {reword?.images?.map((val) => (
                                                    <div className="col-sm-3">
                                                        <img
                                                            src={val?.url}
                                                            // src="https://1.bp.blogspot.com/-zKX8CREi3QY/T2OMZgW3s6I/AAAAAAAAWzg/5sFH754c6sw/s1600/Los-mas-Hermosos-Paisajes-Naturales_04.jpg"
                                                            alt="User Avatar"
                                                            className="img-fluid"
                                                        />
                                                        <Button
                                                            isLoading={rewords}
                                                            onClick={() =>
                                                                handleDeleteFile(
                                                                    val?.id
                                                                )
                                                            }
                                                            type="button"
                                                            className="btn btn-danger right-0 border-0"
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <i className="icon-button fa fa-trash" />
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <ImageUpload
                                            label={t(
                                                'rewards.label.rewardImage'
                                            )}
                                            // image={formik.values.image}
                                            witdh={820}
                                            height={960}
                                            uploadImage={uploadImageRequest}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2 pt-4">
                                    <div className="col-2">
                                        <Link
                                            to="/rewards"
                                            className="btn text-danger"
                                        >
                                            {' '}
                                            {t('general.buttons.cancel')}{' '}
                                        </Link>
                                    </div>
                                    <div className="col-sm-2">
                                        <Button
                                            className="w-10"
                                            isLoading={rewords}
                                            type="submit"
                                            className="btn btn-danger"
                                        >
                                            {' '}
                                            {t(
                                                'general.buttons.saveChanges'
                                            )}{' '}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal centered show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger font-weight-bold">
                        Nuevo paquete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="form-row mt-3 d-flex align-items-center">
                            {PackageList?.map((val, k) => (
                                <div key={k} className="form-group col-md-12">
                                    <Input
                                        placeholder={val?.label}
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            val?.name
                                        )}
                                        type={val?.type || 'text'}
                                        value={formikCreate?.values[val?.name]}
                                    />
                                </div>
                            ))}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        isLoading={loading}
                        className="btn btn-danger"
                        onClick={() => formikCreate?.handleSubmit()}
                    >
                        Crear
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditRewards;
