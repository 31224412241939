import Api from '../../utils/axios';
const api = new Api();

export const getWeighing= async () => {
    const advices = await api.getWeighing();
    return advices;
}

export const createWeighing = async (data) => {
    const advices = await api.createWeighing(data);
    return advices;
}

export const deleteWeighing = async (id) => {
    const advices = await api.deleteWeighing(id);
    return advices.data;
}

export const updateWeighing = async (id, data) => {
    const advices = await api.updateWeighing(id, data);
    return advices;
}

export const getWeighingById = async (id) => {
    const advices = await api.getWeighingById(id);
    return advices;
}