import React, {useState, useEffect} from 'react';
import {ImageUpload, Button, Input, ComboBox, Textarea} from '@components';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import * as usersService from '../../services/rewords';
import Api from '../../utils/axios';

const CreateRewards = () => {
    const api = new Api();
    const history = useHistory();
    const [reward, setReward] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [category, setCategory] = useState(null);
    const [uploadImage, setUploadImage] = useState();
    const [images, setImages] = useState([]);
    const [t] = useTranslation();
    const [categoryId, setCategoryId] = useState();
    const [subCategoryId, setSubCategoryId] = useState();
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            value: 0,
            description: '',
            categoryId: '',
            subCategoryId: '',
            status: 1,
            stock: 0,
            imageId: 1
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.rewardName')),
            value: Yup.number().required(t('validationSchema.rewardValue')),
            description: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.rewardDescription')),
            categoryId: Yup.string().required(
                t('validationSchema.rewardCategory')
            ),
            // subCategoryId: Yup.string().required(
            //     t('validationSchema.rewardSubCategory')
            // ),
            status: Yup.number(),
            stock: Yup.number().required(t('validationSchema.rewardStock'))
        }),
        onSubmit: (values) => {
            sendDataReword(values);
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        api.getCategories()
            .then((response) => {
                setCategories(response.data);
                setCategoryId(response.data.id);
                api.getSubCategories()
                    .then((response) => {
                        setSubCategories(response.data);
                        setCategory(response.data.categoryId);
                        dispatch(setLoading(false));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (formik?.values?.categoryId) {
            api.getSubCategoriesByCategory(formik?.values?.categoryId)
                .then((response) => {
                    dispatch(setLoading(false));
                    setSubCategories(response.data);
                })
                .catch((error) => {
                    dispatch(setLoading(false));
                });
        }
        dispatch(setLoading(true));
    }, [formik?.values?.categoryId]);

    const uploadImageRequest = async (file) => {
        const formData = new FormData();
        const prefix = 'file_';
        const microTime = new Date().getTime() * 1000 + performance.now();
        const extension = file.name.split('.').pop();
        const randomName = `${prefix}${microTime}.${extension}`;
        formData.append('file', new File([file], randomName, { type: file.type }));
        
        try {
            dispatch(setLoading(true));
            setImages((prev) => [...prev, {file, name: file?.name}]);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
        }
    };

    const sendDataReword = async (data) => {
        dispatch(setLoading(true));

        data.categoryId = categoryId;
        data.subCategoryId = subCategoryId;
        // data.imageId = '';

        try {
            const response = await usersService.createReword(data);
            toast.success(t('rewards.label.rewardCreated'));
            if (images?.length > 0) {
                await Promise.all(
                    images?.map(async (img) => {
                        const formData = new FormData();
                        formData.append('file', img?.file);
                        await api.uploadFileReward(
                            response?.data?.id,
                            formData
                        );
                    })
                );
            }

            history.push('/rewards');
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            console.log(error.response.data.errors[0]);
            const errorMessage = error.response.data.errors[0];
            toast.error(errorMessage);
        }
    };

    const handleCategory = (item) => {
        setCategoryId(item.id);
        setCategory(item);
        formik?.setFieldValue('categoryId', item?.id);
    };

    const getPreviewImage = (file) => {
        if (file) {
            return window.URL.createObjectURL(file);
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="card ">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">
                            {t('rewards.label.createReward')}
                        </h3>
                    </div>
                    <div className="p-3">
                        <form
                            className="form-horizontal"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label>
                                            {t('rewards.label.rewardName')}
                                        </label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(
                                                'name'
                                            )}
                                            type="text"
                                            placeholder={t(
                                                'rewards.label.rewardName'
                                            )}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <label>
                                            {t(
                                                'rewards.label.rewarddescription'
                                            )}
                                        </label>
                                        <Textarea
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(
                                                'description'
                                            )}
                                            type="text"
                                            placeholder={t(
                                                'rewards.label.rewarddescription'
                                            )}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {t('rewards.label.category')}
                                        </label>
                                        {categories && categories.length > 0 ? (
                                            <div className="col-sm-4">
                                                <ComboBox
                                                    items={categories}
                                                    optionTitle="name"
                                                    onChange={handleCategory}
                                                    value={categoryId}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'categoryId'
                                                    )}
                                                    placeholder="Subcategoria"
                                                />
                                            </div>
                                        ) : null}

                                        {category && category.hasSubCategory ? (
                                            <>
                                                <label
                                                    htmlFor="inputName"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    {t(
                                                        'rewards.label.subcategory'
                                                    )}
                                                </label>
                                                {subCategories &&
                                                subCategories.length > 0 ? (
                                                    <div className="col-sm-3">
                                                        <ComboBox
                                                            items={
                                                                subCategories
                                                            }
                                                            value={
                                                                subCategoryId
                                                            }
                                                            optionTitle="name"
                                                            onChange={(item) =>
                                                                setSubCategoryId(
                                                                    item.id
                                                                )
                                                            }
                                                            formik={formik}
                                                            formikFieldProps={formik.getFieldProps(
                                                                'subCategoryId'
                                                            )}
                                                            placeholder="Subcategoria"
                                                        />
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputStock"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {t(
                                                'rewards.label.quantityExistence'
                                            )}
                                        </label>
                                        <div className="col-sm-4">
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'stock'
                                                )}
                                                type="number"
                                                placeholder={t(
                                                    'rewards.label.quantityExistence'
                                                )}
                                            />
                                        </div>
                                        <label
                                            htmlFor="inputValor"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('rewards.label.valuePoints')}
                                        </label>
                                        <div className="col-sm-3">
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'value'
                                                )}
                                                type="number"
                                                placeholder={t(
                                                    'rewards.label.valuePoints'
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex">
                                            {images?.map((val, k) => (
                                                <div
                                                    className="col-sm-3"
                                                    key={k}
                                                >
                                                    <img
                                                        src={getPreviewImage(
                                                            val?.file
                                                        )}
                                                        alt="User Avatar"
                                                        className="img-fluid"
                                                    />
                                                    <Button
                                                        onClick={() =>
                                                            setImages((prev) =>
                                                                prev.filter(
                                                                    (x) =>
                                                                        x?.name !==
                                                                        val?.name
                                                                )
                                                            )
                                                        }
                                                        type="button"
                                                        className="btn btn-danger right-0 border-0"
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <i className="icon-button fa fa-trash" />
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <ImageUpload
                                        id="myImage"
                                        label={t('rewards.label.rewardImage')}
                                        image={uploadImage}
                                        witdh={820}
                                        height={960}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to="/rewards"
                                        className="btn text-danger"
                                    >
                                        {' '}
                                        {t('general.buttons.cancel')}{' '}
                                    </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={isSaveLoading}
                                        type="submit"
                                        className="btn btn-danger"
                                    >
                                        {' '}
                                        {t('general.buttons.createReward')}{' '}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateRewards;
